import React from "react";
import Gallery from "react-photo-gallery";
import Measure from "react-measure";
import Lightbox from "react-images";

// Ajouter ici les photos pour le portfolio

const photos = [
  {
    src: "https://i.ibb.co/rF6Pcm0/1.webp",
    width: 3,
    height: 2,
  },{
    src: "https://i.ibb.co/6RYhFxz/2.webp",
    width: 3,
    height: 4,
  },{
    src: "https://i.ibb.co/WKHXRy2/4.webp",
    width: 3,
    height: 3,
  },{
    src: "https://i.ibb.co/8KSfTcz/3.webp",
    width: 3,
    height: 2,
  },{
    src: "https://i.ibb.co/gw91kCV/5.webp",
    width: 3,
    height: 4,
  },{
    src: "https://i.ibb.co/znryTYy/6.webp",
    width: 3,
    height: 2,
  },{
    src: "https://i.ibb.co/2qp2H1B/7.webp",
    width: 4,
    height: 3,
  },{
    src: "https://i.ibb.co/Np8s60F/8.webp",
    width: 3,
    height: 2,
  },{
    src: "https://i.ibb.co/ZGjDm79/9.webp",
    width: 2,
    height: 3,
  },{
    src: "https://i.ibb.co/qmmJgqQ/10.webp",
    width: 3,
    height: 2,
  },
];

class ImageMariageMonika extends React.Component {
  constructor() {
    super();
    this.state = { currentImage: 0 };
    this.closeLightbox = this.closeLightbox.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
  }
  openLightbox(event, obj) {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true,
    });
  }
  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  }
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  }
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  }
  render() {
    const width = this.state.width;
    return (
      <Measure
        bounds
        onResize={(contentRect) =>
          this.setState({ width: contentRect.bounds.width })
        }
      >
        {({ measureRef }) => {
          if (width < 1) {
            return <div ref={measureRef} />;
          }
          let columns = 2;
          if (width >= 480) {
            columns = 3;
          }
          if (width >= 1024) {
            columns = 3;
          }
          if (width >= 1824) {
            columns = 4;
          }
          return (
            <div
              className="gallery"
              ref={measureRef}
              style={{ height: "80vh", overflow: "scroll" }}
            >
              <Gallery
                className="image-gallery"
                photos={photos}
                columns={columns}
                onClick={this.openLightbox}
              />
              <Lightbox
                className="lightbox"
                images={photos}
                onClose={this.closeLightbox}
                onClickPrev={this.gotoPrevious}
                onClickNext={this.gotoNext}
                currentImage={this.state.currentImage}
                isOpen={this.state.lightboxIsOpen}
              />
            </div>
          );
        }}
      </Measure>
    );
  }
}
export default ImageMariageMonika;




























































