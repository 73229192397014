import React from "react";
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";
import Social from "../../components/Social";
import ImageMariageMonika from "../../components/images/ImageMariageMonika";

const PortfolioMariageMonika = () => {
  return (
    <div className="portfolio">
      <Navigation />
      <h3>Monika & Emmanuel</h3>
      <h4>Coordination Jour J</h4>
      <p className="text-portf">
        Monika & Emmanuel m’ont contacté{" "}
        <span className="color-texte">
          une semaine seulement avant leur mariage{" "}
        </span>
        Franco-Polonais…
        <br />
        <br /> Ils avaient préparé leur mariage à distance, aux Pays-Bas, et
        avaient besoin d’une personne pour{" "}
        <span className="color-texte">coordonner</span> les prestataires,{" "}
        <span className="color-texte">gérer </span>
        le timing de la journée, s’occuper d’éventuels imprévus.
        <br />
        <br /> J’ai accepté de relever le{" "}
        <span className="color-texte">défi</span> et j’en ai été ravie !<br />
        <br /> J’ai découvert deux lieux magnifiques :{" "}
        <span className="color-texte">
          la Basilique de la Cité de Carcassonne
        </span>{" "}
        et le Château de Villerambert à Caunes-Minervois. Leurs familles
        respectives m’ont accueillie les bras ouverts, ce qui m’a grandement
        facilité les choses !<br />
        <br /> J’ai rencontré des{" "}
        <span className="color-texte">prestataires de qualité</span> comme
        Virginie PIK (photographe) et Ly Pictures (vidéaste). Tout ceci dans une
        ambiance de folie qui s’est installée dès le vin d’honneur. <br />
        <br />
        Merci à ces deux jeunes mariés pour la <span className="color-texte">confiance</span> qu’ils m’ont accordés !
        <br />
        <br /> Leur <span className="color-texte">Jour J</span> aura été pour moi une très belle expérience !
      </p>
      <ImageMariageMonika />
      <Social />
      <Footer />
    </div>
  );
};

export default PortfolioMariageMonika;
