import React from "react";
import Navigation from "../../components/Navigation";
import ImageMariageLucie from "../../components/images/ImageMariageLucie";
import Footer from "../../components/Footer";
import Social from "../../components/Social";

const PortfolioMariageLucie = () => {
  return (
    <div className="portfolio">
      <Navigation />
      <h3>Julie & Lucien</h3>
      <h4>Histoire d'un Jour Heureux</h4>
      <p className="text-portf">
        Malgré la distance et leurs 3 visites par temps de pluie au <span className="color-texte">Château Bas
        d’Aumelas,</span>  Julie & Lucien ont dû se rendre à l’évidence, et reconnaître
        leur coup de cœur pour ce lieu <span className="color-texte"> bourré de charme.<br /><br /></span> Ils ont eu raison de
        s’écouter puisque le jour J il faisait prés de 39°c… <br/>C’est donc sous une
        chaleur écrasante que nos jeunes mariés et leurs convives ont passé
        cette<span className="color-texte"> journée de noces.<br/><br/></span>  Durant le vin d’honneur, l’orage menaçait et
        quelques gouttes de pluie sont tombées…ce qui n’a pas empêché Emilie
        Flory, grande habituée des lieux, de réaliser de jolies photos de
        couple.<br/><br/> Le dîner de mariage s’est tenu dans <span className="color-texte"> le jardin à la Française,</span> au
        milieu <span className="color-texte"> des vieilles pierres</span> de cette bâtisse datant du XIVème siècle.
        <br/>Quelques éléments de verdures posés sur les tables, des bougies…et le
        tour était joué !<br/> Il ne fallait pas plus pour <span className="color-texte"> sublimer cette soirée.</span>
      </p>
      <ImageMariageLucie />
      <Social />
      <Footer />
    </div>
  );
};

export default PortfolioMariageLucie;
