import React from "react";
import Gallery from "react-photo-gallery";
import Measure from "react-measure";
import Lightbox from "react-images";

// Ajouter ici les photos pour le portfolio

const photos = [
  {
      src: "https://i.ibb.co/h7v0pzC/081.webp",
      width: 3,
      height: 2
  },
  {
      src: "https://i.ibb.co/LY4ts8x/024.webp",
      width: 3,
      height: 2
  },
  {
      src: "https://i.ibb.co/HYjzht6/125.webp",
      width: 3,
      height: 2
  },
  {
      src: "https://i.ibb.co/0G120RM/166.webp",
      width: 2,
      height: 3
  },
  {
      src: "https://i.ibb.co/7pwr1hd/178.webp",
      width: 3,
      height:4
  },
  {
      src: "https://i.ibb.co/zRrtYGZ/187.webp",
      width: 2,
      height: 4
  },
  {
      src: "https://i.ibb.co/F530XGf/188.webp",
      width: 2,
      height: 3
  },
  {
      src: "https://i.ibb.co/z59FhnF/207.webp",
      width: 3,
      height: 2
  },
  {
      src: "https://i.ibb.co/CWgJtRX/233.webp",
      width: 3,
      height: 2
  },
  {
      src: "https://i.ibb.co/MVKWrWb/251.webp",
      width: 3,
      height: 2
  },
  {
      src: "https://i.ibb.co/DzvmXbk/258.webp",
      width: 3,
      height: 2
  },
  {
      src: "https://i.ibb.co/x5vJkNz/260.webp",
      width: 3,
      height: 2
  },
  {
      src: "https://i.ibb.co/CVvkMBD/262.webp",
      width: 3,
      height: 2
  },
  {
      src: "https://i.ibb.co/BVqPCn9/283.webp",
      width: 3,
      height: 2
  },
  {
      src: "https://i.ibb.co/CvTQD2t/291.webp",
      width: 2,
      height: 3
  },
  {
      src: "https://i.ibb.co/Rb46FL2/406-NB.webp",
      width: 2,
      height: 3
  },
  {
      src: "https://i.ibb.co/Q80774s/426-NB.webp",
      width: 3,
      height: 2
  },
  {
      src: "https://i.ibb.co/Ch9YQp3/407.webp",
      width: 3,
      height: 2
  },
  {
      src: "https://i.ibb.co/3ym28n8/517.webp",
      width: 3,
      height: 2
  },
  {
      src: "https://i.ibb.co/VHHVk5w/439.webp",
      width: 3,
      height: 2
  },
  {
      src: "https://i.ibb.co/yPCJwJ4/539.webp",
      width: 3,
      height: 2
  },
  {
      src: "https://i.ibb.co/W2QyZck/602.webp",
      width: 3,
      height: 2
  },
  {
      src: "https://i.ibb.co/bdFGVKs/612.webp",
      width: 3,
      height: 2
  },
  {
      src: "https://i.ibb.co/ZXdsys8/629.webp",
      width: 3,
      height: 2
  },
  {
      src: "https://i.ibb.co/YpFsVB2/665.webp",
      width: 3,
      height: 2
  },
  {
      src: "https://i.ibb.co/3r91XRC/687.webp",
      width: 3,
      height: 2
  },
  {
      src: "https://i.ibb.co/F88SMKP/699.webp",
      width: 2,
      height: 3
  },
  {
      src: "https://i.ibb.co/NsN2Szz/20220903-180850.webp",
      width: 3,
      height: 2
  }
];


class ImageMariageVanessa extends React.Component {
  constructor() {
    super();
    this.state = { currentImage: 0 };
    this.closeLightbox = this.closeLightbox.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
  }
  openLightbox(event, obj) {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true
    });
  }
  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false
    });
  }
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1
    });
  }
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1
    });
  }
  render() {
    const width = this.state.width;
    return (
      <Measure
        bounds
        onResize={contentRect =>
          this.setState({ width: contentRect.bounds.width })
        }
      >
        {({ measureRef }) => {
          if (width < 1) {
            return <div ref={measureRef} />;
          }
          let columns = 2;
          if (width >= 480) {
            columns = 3;
          }
          if (width >= 1024) {
            columns = 3;
          }
          if (width >= 1824) {
            columns = 4;
          }
          return (
            <div className="gallery" ref={measureRef} style={{ height: "80vh", overflow: "scroll" }}>
              <Gallery
                className="image-gallery"
                photos={photos}
                columns={columns}
                onClick={this.openLightbox}
              />
              <Lightbox
              className="lightbox"
                images={photos}
                onClose={this.closeLightbox}
                onClickPrev={this.gotoPrevious}
                onClickNext={this.gotoNext}
                currentImage={this.state.currentImage}
                isOpen={this.state.lightboxIsOpen}
              />
            </div>
          );
        }}
      </Measure>
    );
  }
}
export default ImageMariageVanessa;




























































