import React from "react";
import signature from '../assets/_LOGO_SVG/Fichier 5.svg'
import weeding from '../assets/_LOGO_SVG/wedding.webp'
import Zoom from 'react-reveal/Zoom';



const PresentationLucie = () => {
  return (
    <div className="presentation">
    <div className="img-presentation">
   <Zoom><img className="img-lucie" src="https://i.ibb.co/nr4sKYt/lucie-portrait-21-11zon.jpg" alt="img-lucie" /></Zoom> 
    <img className="weeding" src={weeding} alt="lucie-manresa-weeding"/>
    </div>
    <div className="text-presentation-lucie">
    <p>
    A la fois sage-femme et organisatrice d’évènements, mon quotidien est un
    véritable <span className="color-p">ascenseur émotionnel ! </span><br/> <br/>Je suis au cœur des<span className="color-p"> grands moments de
    la Vie. </span><br/> Bienveillante, à l’écoute, rigoureuse et organisée, je serai
    votre personne ressource lors des préparatifs de votre mariage, du
    baptême de votre enfant ou pour tout autre évènement privé. <br/><br/> Diplômée de
    la<span className="color-p"> Wedding Academy,</span> j’ai pu acquérir des bases solides et des
    compétences indispensables à l’exercice de ce métier, car ces <span className="text-bold"> Jours
    Heureux</span> ne s’improvisent pas.<br/> <br/>Alors si vous manquez de temps ou que les
    préparatifs vous pèsent, je vous invite à me contacter. <span className="color-p">Je vous
    accompagnerai</span> avec plaisir dans cette belle aventure !
    </p>
      <img className="signature" src={signature} alt="lucie-manresa-signature"/>
    </div>
    </div>
  );
};

export default PresentationLucie;
