import React from "react";
import Gallery from "react-photo-gallery";
import Measure from "react-measure";
import Lightbox from "react-images";


// Ajouter ici les photos pour le portfolio

const photos = [
  {
      src: "https://i.ibb.co/M9BG7JC/1.jpg",
      width: 2,
      height: 3
  },
  {
    src: "https://i.ibb.co/bsTQMKm/2.jpg",
    width: 2,
    height: 3
  },
  {
    src: "https://i.ibb.co/9Gb5Fpj/3.jpg",
    width: 2,
    height:3
  },
  {
    src:
      "https://i.ibb.co/3MZYz43/4.jpg",
    width: 2,
    height: 2
  },
  {
    src: "https://i.ibb.co/Mgp4750/6.jpg",
    width: 3,
    height: 5
  },
  {
    src: "https://i.ibb.co/QJsz8RD/5.jpg",
    width: 2,
    height: 2
  },
  {
    src:
      "https://i.ibb.co/9ykyX8R/7.jpg",
    width: 2,
    height:3
  },
  {
    src:
      "https://i.ibb.co/41P56pZ/8.jpg",
    width: 4,
    height:3
  },
  {
    src:
      "https://i.ibb.co/mXP0y06/mairie-94.jpg",
    width: 3,
    height:4
  },
  {
    src:
      "https://i.ibb.co/1rkH1Sh/9.jpg",
    width: 3,
    height:4
  },
  {
    src:
      "https://i.ibb.co/6nGXyRf/10.jpg",
    width: 3,
    height:2
  },
  {
    src:
      "https://i.ibb.co/p33vmfv/11.jpg",
    width: 2,
    height:3
  },
  {
    src:
      "https://i.ibb.co/QryfN4N/12.jpg",
    width: 4,
    height:3
  },
  {
    src:
      "https://i.ibb.co/W3BZ6DT/13.jpg",
    width: 4,
    height:3
  },
  {
    src:
      "https://i.ibb.co/tCMfFMV/14.jpg",
    width: 3,
    height:2
  },
  {
    src:
      "https://i.ibb.co/nbyvzst/15.jpg",
    width: 2,
    height:3
  },
  {
    src:
      "https://i.ibb.co/HVXdp4P/16.jpg",
    width: 3,
    height:4
  },
  {
    src:
      "https://i.ibb.co/VqBYbsr/17.jpg",
    width: 4,
    height:3
  },
  {
    src:
      "https://i.ibb.co/4R793D0/18.jpg",
    width: 2,
    height:3
  },
  {
    src:
      "https://i.ibb.co/bBpX730/19.jpg",
    width: 3,
    height:2
  },
  {
    src:
      "https://i.ibb.co/kmNV4JB/20.jpg",
    width: 4,
    height:3
  },
  
  {
    src:
      "https://i.ibb.co/C8xnvX5/21.jpg",
    width: 4,
    height:3
  },
  {
    src:
      "https://i.ibb.co/v4jFt6n/22.jpg",
    width: 3,
    height:2
  },
  {
    src:
      "https://i.ibb.co/1MfP91j/23.jpg",
    width: 2,
    height:3
  },
  {
    src:
      "https://i.ibb.co/L87Vg3B/24.jpg",
    width: 2,
    height:3
  },
  {
    src:
      "https://i.ibb.co/r2g3kXd/25.jpg",
    width: 3,
    height:4
  },
  {
    src:
      "https://i.ibb.co/L0jBhqB/26.jpg",
    width: 4,
    height:3
  },
  {
    src:
      "https://i.ibb.co/xMYMNxY/27.jpg",
    width: 3,
    height:2
  },
  {
    src:
      "https://i.ibb.co/R9zHmfg/28.jpg",
    width: 2,
    height:3
  },
  {
    src:
      "https://i.ibb.co/gv9n2nx/29.jpg",
    width: 4,
    height:4
  },
  {
    src:
      "https://i.ibb.co/V262kfT/31-bis.jpg",
    width: 3,
    height:4
  },
  {
    src:
      "https://i.ibb.co/j6YYxbZ/30.jpg",
    width: 3,
    height:2
  },
  {
    src:
      "https://i.ibb.co/F6YG0Zf/31.jpg",
    width: 3,
    height:3
  },
  {
    src:
      "https://i.ibb.co/cJPf8p1/32.jpg",
    width: 3,
    height:5
  },
  {
    src:
      "https://i.ibb.co/njgwKj7/33.jpg",
    width: 3,
    height:4
  },
  {
    src:
      "https://i.ibb.co/pxNyFSp/34.jpg",
    width: 4,
    height:3
  }

];


class ImageAurelie extends React.Component {
  constructor() {
    super();
    this.state = { currentImage: 0 };
    this.closeLightbox = this.closeLightbox.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
  }
  openLightbox(event, obj) {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true
    });
  }
  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false
    });
  }
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1
    });
  }
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1
    });
  }
  render() {
    const width = this.state.width;
    return (
      <Measure
        bounds
        onResize={contentRect =>
          this.setState({ width: contentRect.bounds.width })
        }
      >
        {({ measureRef }) => {
          if (width < 1) {
            return <div ref={measureRef} />;
          }
          let columns = 2;
          if (width >= 480) {
            columns = 3;
          }
          if (width >= 1024) {
            columns = 3;
          }
          if (width >= 1824) {
            columns = 4;
          }
          return (
            <div className="gallery" ref={measureRef} style={{ height: "80vh", overflow: "scroll" }}>
              <Gallery
                className="image-gallery"
                photos={photos}
                columns={columns}
                onClick={this.openLightbox}
              />
              <Lightbox
              className="lightbox"
                images={photos}
                onClose={this.closeLightbox}
                onClickPrev={this.gotoPrevious}
                onClickNext={this.gotoNext}
                currentImage={this.state.currentImage}
                isOpen={this.state.lightboxIsOpen}
              />
            </div>
          );
        }}
      </Measure>
    );
  }
}
export default ImageAurelie;
