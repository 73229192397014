import React from "react";
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";
import Social from "../../components/Social";
import ImageMariageCeline from "../../components/images/ImageMariageCeline";

const PortfolioMariageCeline = () => {
  return (
    <div className="portfolio">
      <Navigation />
      <h3>Celine & Patrick</h3>
      <p className="text-portf">
      Un couple que j’ai <span className="color-texte">accompagné</span> avec la formule <span className="color-texte">« Conseil ».</span><br/><br/> 
      <span className="color-texte">Trois rendez-vous</span> seulement qui leur ont permis d’y voir plus clair, et d’organiser, en toute <span className="color-texte">autonomie</span>, leur mariage en petit comité.
      <br/><br/>Je leur renouvelle tous mes vœux de bonheur !!      
      </p>
      <ImageMariageCeline />
      <Social />
      <Footer />
    </div>
  );
};

export default PortfolioMariageCeline;