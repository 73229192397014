import React from 'react';
import Logo from "../assets/_LOGO_SVG/papillon.webp"
import { useState } from 'react'
import { NavLink } from 'react-router-dom'

const LogoScrool = () => {
  
    const [visible, setVisible] = useState(false)
  
    const toggleVisible = () => {
      const scrolled = document.documentElement.scrollTop;
      if (scrolled > 130){
        setVisible(true)
      } 
      else if (scrolled <= 130){
        setVisible(false)
      }
    };
    
    
  
    
    window.addEventListener('scroll', toggleVisible);
    
    return (
      <NavLink to="/">
       <div 
       style={{opacity: visible ? '1' : '0'}} class="logo-scroll">
       <div><img src={Logo} alt="" className="logo-nav"/></div>
          </div>
          </NavLink>
    );
  }
    

export default LogoScrool;