import React from 'react';
import { FaMapMarkedAlt } from "@react-icons/all-files/fa/FaMapMarkedAlt";
import { FaPhoneAlt } from "@react-icons/all-files/fa/FaPhoneAlt";
import { FiMail } from "@react-icons/all-files/fi/FiMail";

const CoordLucie = () => {
    return (

        // Coordonnées Contact

        <div className="info">
            <FaMapMarkedAlt className='icon' />
            <p>05 rue croix de la Reille 34500 Beziers</p>
            <a href="tel:+33630538022"><FaPhoneAlt className='icon' /></a>
            <p>+33 6 80 50 32 75</p>
            <FiMail className='icon' />
            <p className="email">contact<span>@desjoursheureux.fr</span></p>
        </div>
    );
};

export default CoordLucie;