import React from "react";
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";
import Social from "../../components/Social";
import ImageMariageLJ from "../../components/images/ImageMariageLJ";

const PortfolioMariageLj = () => {
  return (
    <div className="portfolio">
      <Navigation />
      <h3>Laurie & Julien</h3>
      <h4>Au milieu des chevaux</h4>
      <p className="text-portf">
        Le lieu de réception de ce mariage était <span className="color-texte">hors du commun</span>  puisque nous
        étions au cœur d’un haras…magnifique ! <br/><br/> Un <span className="color-texte">cadre exceptionnel</span> auquel
        Laurie, notre mariée, est particulièrement attachée :<br/> C’est ici que <span className="color-texte">sa
        jument, Paloma, est hébergée.</span> <br/><br/>Pour la première fois, le Domaine de
        Prades ouvrait ses portes à <span className="color-texte">des mariés.</span> Une première mais pas une
        dernière fois… <br/>Ce mariage a donné envie aux propriétaires de se lancer
        dans l’évènementiel.
      </p>
      <ImageMariageLJ />
      <Social />
      <Footer />
    </div>
  );
};

export default PortfolioMariageLj;
