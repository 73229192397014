import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import { NavLink } from "react-router-dom";

// import required modules
import { Pagination, Autoplay } from "swiper";

export default function App() {
  return (
    <>
    <h2 className="title-carousel">Galerie</h2>
    
    
    <Swiper
    breakpoints={{
          // Responsive Carousel, BreakPoint Nombre d'image
          // when window width is >= 640px
          0: {
            width: 300,
            slidesPerView: 1,
          },
          480: {
            slidesPerView: 3,
          },
        }}
        slidesPerView={3}
        spaceBetween={10}
        loop={true}
        speed={5000}
        autoplay={true}
        modules={[Pagination, Autoplay]}
        className="mySwiper"

        // Image slide 

      >
        <SwiperSlide>
          <img src="https://i.ibb.co/JtFM0S2/2-2-11zon.jpg" alt="images-evenements" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="https://i.ibb.co/SfDYj1G/140-11-11zon.jpg" alt="images-evenements" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="https://i.ibb.co/rZtT6bz/20-10-11zon.jpg" alt="images-evenements" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="https://i.ibb.co/yd120GV/DSC-7126.webp" alt="images-evenements" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="https://i.ibb.co/gSPRBnq/DSC-6744-17-11zon.jpg" alt="images-evenements" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="https://i.ibb.co/JRccWsR/18h00-90-resultat-8-11zon.jpg" alt="images-evenements" />
        </SwiperSlide>
        <SwiperSlide>
          <img src="https://i.ibb.co/1nH957r/DSC-7114-18-11zon.jpg" alt="images-evenements" />
        </SwiperSlide>
      </Swiper>
      <div className="btn-galerie">
      <NavLink className="more-picture fill" to="/portfolio">
      Voir plus 
    </NavLink>
      </div>
    </>
  );
}
