import React from "react";
import Logo from "../components/Logo";
import Navigation from "../components/Navigation";
import PresentationLucie from "../components/PresentationLucie";
import PrestationAccueil from "../components/PrestationAccueil";
import Carousel from "../components/Carousel";
import Social from "../components/Social";
import Footer from "../components/Footer";
import { motion } from "framer-motion";

const Home = () => {
  return (
    <motion.div
      className="home"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, display: "none", transition:{duration: 2} }}
    >
      <div className="header">
        <div className="header-inside">
          <Navigation />
          <Logo />
          <h1>Wedding Planner Béziers</h1>
        </div>
        </div>
        <h2 className="titre-pres">Des Jours Heureux</h2>
        <PresentationLucie />
      <PrestationAccueil />
      <Carousel />
      <Social />
      <Footer />
    </motion.div>
  );
};

export default Home;
