import React from "react";
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";
import Social from "../../components/Social";
import ImageWa from "../../components/images/ImageWa";

const PortfolioWa = () => {
  return (
    <div className="portfolio">
      <Navigation />
      <h3>Shooting d'inspiration mariage</h3>
      <p className="text-portf">
        Dans le cadre de ma formation, la <span className="color-texte">Wedding Academy</span> nous a proposé de
        réaliser un shooting d’inspiration sur le thème des  
        <span className="color-texte"> Chroniques de Bridgerton.</span><br/><br/> Nous avons mis en scène un couple, Marion et Jean-Luc, au
        magnifique <span className="color-texte">Château de la Baronnie,</span> avec la participation bénévole de
        nombreux prestataires. <br/><br/>Cette journée riche en expériences, nous a permis
        de mettre en pratique nos connaissances et de rencontrer des
        <span className="color-texte">professionnels de qualité.</span>
      </p>
      <ImageWa />
      <div className="prestataire">
        <h4>Prestataire : </h4>
        <ul>
          <li><span>Ecole</span> : Wedding academy</li>
          <li><span>Formatrice</span> : Sarah Lacroix</li>
          <li><span>Fleuriste</span> : Gali M</li>
          <li><span>Photographe </span>: Guillaume Gimenez</li>
          <li><span>Vidéastes</span> : Mat’ Events Production & Alias Noah</li>
          <li><span>Lieu de réception</span> : Le Château de la Baronnie</li>
          <li>
            <span>Créateur de robe de mariée & demoiselles d’honneur </span>: Cymbeline
            Toulouse
          </li>
          <li><span>Alliance & bague de fiançailles </span>: Bijouterie Mohedano</li>
          <li><span>Costume</span> : Father & Sons</li>
          <li><span>Accessoires tenue du marié</span> : Father & Sons</li>
          <li><span>Modèles</span> : Marion & Jean-Luc Tournier</li>
          <li><span>Coiffeuse / Maquilleuse</span> : Sacha makeup pro</li>
          <li><span>Calligraphe</span> : Anne Tassié Sacramento</li>
          <li><span>Musicienne </span>: Harpissima</li>
          <li><span>Accessoires/Décoration</span> : CM Tendance</li>
          <li><span>Artificier</span> : Stellar Pyrotechnie</li>
          <li><span>Wedding Cake</span> : Ange Gimenez</li>
        </ul>
        <h4>Wedding Planners : </h4>
        <ul>
          <li>Corinne PERIGNON</li>
          <li>Nelly MONTAGNAC</li>
          <li>Montaine MONTAGNAC</li>
          <li>Sandra SDIRI</li>
          <li>Aurélie FERRARI</li>
          <li>Lucie MANRESA</li>
          <li>Alizée HEEMS</li>
          <li>Laurence KLAIN</li>
        </ul>
      </div>
      <Social />
      <Footer />
    </div>
  );
};

export default PortfolioWa;
