import React from "react";
import Gallery from "react-photo-gallery";
import Measure from "react-measure";
import Lightbox from "react-images";

// Ajouter ici les photos pour le portfolio

const photos = [
  {
    src: "https://i.ibb.co/HXYcN3r/1.webp",
    width: 3,
    height: 4,
  },
  {
    src: "https://i.ibb.co/R4TPwPr/2.webp",
    width: 3,
    height: 2,
  },
  {
    src: "https://i.ibb.co/1fVqrN0/3.webp",
    width: 3,
    height: 2,
  },
  {
    src: "https://i.ibb.co/SccnHkB/4.webp",
    width: 3,
    height: 2,
  },
  {
    src: "https://i.ibb.co/k1Yzy9K/5.webp",
    width: 3,
    height: 2,
  },
  {
    src: "https://i.ibb.co/0BwckW6/6.webp",
    width: 2,
    height: 3,
  },
  {
    src: "https://i.ibb.co/HGyM0qQ/7.webp",
    width: 3,
    height: 2,
  },
  {
    src: "https://i.ibb.co/zXDCmz4/8.webp",
    width: 3,
    height: 2,
  },
  {
    src: "https://i.ibb.co/DR0Mm7V/9.webp",
    width: 4,
    height: 2,
  },
  {
    src: "https://i.ibb.co/v3Mbrr6/10.webp",
    width: 3,
    height: 3,
  }
];

class ImageAnniversaireSophie extends React.Component {
  constructor() {
    super();
    this.state = { currentImage: 0 };
    this.closeLightbox = this.closeLightbox.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
  }
  openLightbox(event, obj) {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true,
    });
  }
  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  }
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  }
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  }
  render() {
    const width = this.state.width;
    return (
      <Measure
        bounds
        onResize={(contentRect) =>
          this.setState({ width: contentRect.bounds.width })
        }
      >
        {({ measureRef }) => {
          if (width < 1) {
            return <div ref={measureRef} />;
          }
          let columns = 2;
          if (width >= 480) {
            columns = 3;
          }
          if (width >= 1024) {
            columns = 3;
          }
          if (width >= 1824) {
            columns = 4;
          }
          return (
            <div
              className="gallery"
              ref={measureRef}
              style={{ height: "80vh", overflow: "scroll" }}
            >
              <Gallery
                className="image-gallery"
                photos={photos}
                columns={columns}
                onClick={this.openLightbox}
              />
              <Lightbox
                className="lightbox"
                images={photos}
                onClose={this.closeLightbox}
                onClickPrev={this.gotoPrevious}
                onClickNext={this.gotoNext}
                currentImage={this.state.currentImage}
                isOpen={this.state.lightboxIsOpen}
              />
            </div>
          );
        }}
      </Measure>
    );
  }
}
export default ImageAnniversaireSophie;



















































