import React from 'react';
import Logo from '../components/Logo';
import Navigation from '../components/Navigation';
import Card from '../components/Card';
import Social from '../components/Social';
import Footer from '../components/Footer';
import { motion } from "framer-motion"



const Portfolio = () => {

  const dataEvent = 
        [{
            "mariages": [
                {
                    "id": 1,
                    "name": "Julie & Lucien",
                    "description": "Malgré la distance et leurs 3 visites par temps de pluie au Château Bas d’ Aumelas, Julie & Lucien ont dû se rendre à l’évidence, et reconnaître leur coup de cœur pour ce lieu bourré de charme. Ils ont eu raison de s’écouter puisque le jour J il faisait prés de 39°c… C’est donc sous une chaleur écrasante que nos jeunes mariés et leurs convives ont passé cette journée de noces. Durant le vin d’honneur, l’orage menaçait  et quelques gouttes de pluie sont tombées...",
                    "image": "https://i.ibb.co/6PFf15b/couple-95-15-11zon.jpg",
                    "path": "/portfolio/lucie"
                },
                {
                    "id": 2,
                    "name": "Un mariage scellé par l'Amour familial",
                    "description": "",
                    "image": "https://i.ibb.co/v4jFt6n/22.jpg",
                    "path": "/portfolio/aurelie"
                },
                {
                    "id": 3,
                    "name": "Shooting d'inspiration mariage",
                    "description": "Dans le cadre de ma formation, la Wedding Academy nous a proposé de réaliser un shooting d'inspiration sur le thème des chroniques de Bridgerton. Nous avons mis en scène un couple, Marion et Jean-Luc, au magnifique Château de la Baronnie, avec la participation bénévole de nombreux prestataires...",
                    "image": "https://i.ibb.co/XtKMMWj/13.webp",
                    "path": "/portfolio/wedding-academy"
                },
                {
                    "id": 4,
                    "name": "Cécile & Patrick",
                    "description": "Un couple que j’ai accompagné avec la formule « Conseil ».Trois rendez-vous seulement qui leur ont permis d’y voir plus clair.",
                    "image": "https://i.ibb.co/SwWkF7c/AMt-YUWc3-w-GZnyim-G-4-Ylr-Ywb5-Cp-M7g-Twmgv-B5-Qee-BKg-NUR3q-IRFid5-QWFZx-G3-D4-Gy-E9ng89-UUO3k-SSG.webp",
                    "path": "/portfolio/celine"
                },
                {
                    "id": 5,
                    "name": "Au milieu des chevaux",
                    "description": "Un couple que j’ai accompagné avec la formule « Conseil ».Trois rendez-vous seulement qui leur ont permis d’y voir plus clair.",
                    "image": "https://i.ibb.co/C0Xz50S/22.webp",
                    "path": "/portfolio/l&j"
                },
                {
                    "id": 6,
                    "name": "Un mariage bien mérité",
                    "description": "Après 2 ans et demi d’attente (Covid oblige) et des préparatifs semés d’embûches, ces 2  là ont finis par se dire OUI.",
                    "image": "https://i.ibb.co/Ch9YQp3/407.webp",
                    "path": "/portfolio/v&f"
                },
                {
                    "id": 7,
                    "name": "Coordination Jour J",
                    "description": "Après 2 ans et demi d’attente (Covid oblige) et des préparatifs semés d’embûches, ces 2  là ont finis par se dire OUI.",
                    "image": "https://i.ibb.co/qmmJgqQ/10.webp",
                    "path": "/portfolio/m&m"
                }
            ],
            "baptemes": [
                {
                    "id": 1,
                    "name": "Irène",
                    "description": "Recette pour réussir un joli baptême : A la naissance, donnez à votre petite fille un doux prénom empreint de votre histoire familiale. Laisser grandir pendant 9 bons mois. Le 19 juin 2021, mélangez la famille et les proches dans le jardin de la marraine d’Irène...",
                    "image": "https://i.ibb.co/9sthwft/10.webp",
                    "path": "/portfolio/irene"
                },
                {
                    "id": 2,
                    "name": "Juliette",
                    "image": "https://i.ibb.co/0yKCGjh/DSC-7337-1-19-11zon.jpg",
                    "path": "/portfolio/juliette"
                }
            ],
            "demande": [
                {
                    "id": 1,
                    "name": "La corde au cou",
                    "description": "",
                    "image": "https://i.ibb.co/QCYvgqm/5-4-11zon.jpg",
                    "path": "/portfolio/charlene"
                }
            ],
            "anniversaire": [
                {
                    "id": 1,
                    "name": "Un petit air marin",
                    "description": "",
                    "image": "https://i.ibb.co/r2SRr2j/4.webp",
                    "path": "/portfolio/anniv&l"
                },
                {
                    "id": 2,
                    "name": "Anniversaire Sophie & Julien",
                    "description": "",
                    "image": "https://i.ibb.co/k1Yzy9K/5.webp",
                    "path": "/portfolio/anniv&s"
                }
            ]
        }];

    return (
        <motion.div className="portfolio" initial={{opacity:0}}
        animate={{opacity:1}}
        exit={{opacity:0,display:"none", transition:{duration: 2}}}>
        <div className="header">
        <div className="header-inside">
          <Navigation />
          <Logo />
            <h1>Portfolio</h1>
        </div>
        
        </div>
        <h2 className="titre-pres">Des Jours Heureux</h2>
      <p className="discover-picture">Revivez en images l'ensemble des évènements que j'ai suivi de près ou de loin.</p>
      <div className="event">
            <Card
            dataEvent={dataEvent} />
        </div>
        <Social />
        <Footer />
        </motion.div>
    );
};

export default Portfolio;