import React from "react";
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";

const Cgv = () => {
  return (
    <div className="mention">
      <Navigation />
      <div className="text-mention">
      <h4>Conditions Générales de ventes</h4>
      <p>
      Les présentes conditions générales de vente (CGV) sont relatives à la
      société Des Jours Heureux. Elles constituent le cadre des engagements
      contractuels et financiers proposés à ses clients.Ces CGV sont
      adressées au client en même temps que le devis suite à la demande du
      client qui s’est montré intéressé par les prestations proposées par
      l’agence.Les CGV s’appliquent à toutes les prestations effectuées par
      l’agence. Elles précèdent le contrat de prestation qui, une fois signé
      prévaudra sur les présentes conditions générales. 
      </p>
      <h4>Prestations et tarifs</h4>
      <p>
      Des Jours Heureux est spécialisée dans l’organisation d’évènements .
        Elle assume, à l’occasion la préparation et l’organisation de
        l’évènement au nom et pour le compte de ses clients, les prestations
        dont l’exécution lui est confiée.Les prix figurants sur le devis sont
        valables pour une durée de 1 mois à compter de l’édition de celui-ci.La
        prestation comprend tout ce qui est explicitement listé sur le devis. De
        façon corollaire, elle ne comprend pas ce qui n’y est pas
        mentionné.Toute prestation ne figurant pas dans la présente proposition
        pourra faire l’objet d’un devis complémentaire gratuit.
      </p>
      <h4>Modalités de
        paiement</h4>
        <p>
          Toute commande et réservation n’est validée qu’à réception d’un
        acompte de 30% du total net à régler. Le reste de la somme est à régler
        selon les modalités fixées sur le devis et dans le contrat.Le paiement
        de la totalité de la prestation s’effectue au plus tard 1 semaine avant
        la date de l’évènement. L’agence accepte les moyens de paiement suivants
        : espèces, chèque ou virement
        bancaire.</p>
        <h4>Frais annexes</h4>
        <p>Il est porté à la connaissance des clients que
        des frais supplémentaires sont à prévoir en sus du tarif indiqué. Le
        tarif des frais de déplacement est de 0,60€/Km au dela de l’adresse de
        la société. Les 30 premiers km de chaque déplacement sont offerts. Les
        frais de repas et d’hébergement sont également en sus.</p>  
      </div>
      <Footer />
    </div>
  );
};

export default Cgv;
