import React from "react";
import Gallery from "react-photo-gallery";
import Measure from "react-measure";
import Lightbox from "react-images";

// Ajouter ici les photos pour le portfolio

const photos = [
  {
    src: "https://i.ibb.co/crbTZYs/1.webp",
    width: 3,
    height: 2,
  },
  {
    src: "https://i.ibb.co/ZJrHFpJ/3.webp",
    width: 2,
    height: 3,
  },
  {
    src: "https://i.ibb.co/4Nc1Lh5/2.webp",
    width: 3,
    height: 3,
  },
  {
    src: "https://i.ibb.co/xGCMCD0/5.webp",
    width: 3,
    height: 3,
  },
  {
    src: "https://i.ibb.co/F0LwswB/4.webp",
    width: 3,
    height: 5,
  },
  {
    src: "https://i.ibb.co/fMn6jYh/9.webp",
    width: 4,
    height: 3,
  },
  {
    src: "https://i.ibb.co/YcJjz03/10.webp",
    width: 4,
    height: 3,
  },
  {
    src: "https://i.ibb.co/2PqYrVc/16.webp",
    width: 4,
    height: 4,
  },
  {
    src: "https://i.ibb.co/JyxDVHd/17.webp",
    width: 4,
    height: 3,
  },
  {
    src: "https://i.ibb.co/XbsVN7Q/20.webp",
    width: 3,
    height: 4,
  },
  {
    src: "https://i.ibb.co/XCXWhNh/21.webp",
    width: 3,
    height: 3,
  },
  {
    src: "https://i.ibb.co/0rzfS4P/34.webp",
    width: 4,
    height: 4,
  },
  {
    src: "https://i.ibb.co/2gLcChq/30.webp",
    width: 3,
    height: 3,
  },
  {
    src: "https://i.ibb.co/sjhMBfD/35.webp",
    width: 5,
    height: 4,
  },
  {
    src: "https://i.ibb.co/C2mMbB7/41.webp",
    width: 5,
    height: 4,
  },
  {
    src: "https://i.ibb.co/9hFNxVX/40.webp",
    width: 4,
    height:3,
  },
  {
    src: "https://i.ibb.co/jykzYZ8/56.webp",
    width: 3,
    height: 3,
  },
  {
    src: "https://i.ibb.co/ZBZ9LMf/60.webp",
    width: 3,
    height: 3,
  },
  {
    src: "https://i.ibb.co/q5pbFzg/63.webp",
    width: 5,
    height: 4,
  },
  {
    src: "https://i.ibb.co/4Khzgkb/76.webp",
    width: 3,
    height:3,
  },
  {
    src: "https://i.ibb.co/hVxYPYd/78.webp",
    width: 3,
    height: 3,
  },
  {
    src: "https://i.ibb.co/x3FZ133/82.webp",
    width: 3,
    height: 3,
  },
  {
    src: "https://i.ibb.co/hdKyFqh/83.webp",
    width: 2,
    height: 3,
  },
  {
    src: "https://i.ibb.co/hY7nckk/90.webp",
    width: 3,
    height:3,
  },
  {
    src: "https://i.ibb.co/R2b3dM5/104.webp",
    width: 3,
    height: 3,
  },
  {
    src: "https://i.ibb.co/kgJdTqX/105.webp",
    width: 5,
    height: 4,
  },
  {
    src: "https://i.ibb.co/YPKBwHf/106.webp",
    width: 3,
    height: 3,
  },
  {
    src: "https://i.ibb.co/C8vK8dP/95.webp",
    width: 4,
    height: 3,
  },
  {
    src: "https://i.ibb.co/mzCstC1/108.webp",
    width: 4,
    height: 3,
  },
  {
    src: "https://i.ibb.co/Kq0FXMC/113.webp",
    width: 4,
    height: 4,
  },
  {
    src: "https://i.ibb.co/znY7815/114.webp",
    width: 5,
    height: 4,
  },
  {
    src: "https://i.ibb.co/smzfwTr/115.webp",
    width: 5,
    height: 4,
  },
  {
    src: "https://i.ibb.co/T1gxFwT/123.webp",
    width: 5,
    height: 4,
  },
  {
    src: "https://i.ibb.co/wwY189y/119.webp",
    width: 5,
    height: 4,
  },
  {
    src: "https://i.ibb.co/sPT7Qgx/125.webp",
    width: 5,
    height: 4,
  },
  {
    src: "https://i.ibb.co/Wp8wGdH/127.webp",
    width: 4,
    height: 4,
  },
  {
    src: "https://i.ibb.co/GMjstS9/128.webp",
    width: 5,
    height: 4,
  },
  {
    src: "https://i.ibb.co/hML7PzV/124.webp",
    width: 3,
    height: 4,
  },
  {
    src: "https://i.ibb.co/Xs4pnKM/137.webp",
    width: 5,
    height: 4,
  },
  {
    src: "https://i.ibb.co/fMX8vwg/129.webp",
    width: 5,
    height: 4,
  },
  {
    src: "https://i.ibb.co/rMdWXJr/138.webp",
    width: 4,
    height: 4,
  },
  {
    src: "https://i.ibb.co/SVs6zcz/140.webp",
    width: 5,
    height: 4,
  },
  {
    src: "https://i.ibb.co/5B4vNYN/142.webp",
    width: 3,
    height: 4,
  },
  {
    src: "https://i.ibb.co/z4RNKp7/145.webp",
    width: 4,
    height: 3,
  },
  {
    src: "https://i.ibb.co/CW2m49j/141.webp",
    width: 5,
    height: 4,
  }
];

// to best see the results, click the popout button in the preview browser
// and resize your browser

class ImageMariageLucie extends React.Component {
  constructor() {
    super();
    this.state = { currentImage: 0 };
    this.closeLightbox = this.closeLightbox.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
  }
  openLightbox(event, obj) {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true,
    });
  }
  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  }
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  }
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  }
  render() {
    const width = this.state.width;
    return (
      <Measure
        bounds
        onResize={(contentRect) =>
          this.setState({ width: contentRect.bounds.width })
        }
      >
        {({ measureRef }) => {
          if (width < 1) {
            return <div ref={measureRef} />;
          }
          let columns = 2;
          if (width >= 480) {
            columns = 3;
          }
          if (width >= 1024) {
            columns = 3;
          }
          if (width >= 1824) {
            columns = 4;
          }
          return (
            <div
              className="gallery"
              ref={measureRef}
              style={{ height: "80vh", overflow: "scroll" }}
            >
              <Gallery
                className="image-gallery"
                photos={photos}
                columns={columns}
                onClick={this.openLightbox}
              />
              <Lightbox
                className="lightbox"
                images={photos}
                onClose={this.closeLightbox}
                onClickPrev={this.gotoPrevious}
                onClickNext={this.gotoNext}
                currentImage={this.state.currentImage}
                isOpen={this.state.lightboxIsOpen}
              />
            </div>
          );
        }}
      </Measure>
    );
  }
}
export default ImageMariageLucie;
