import React from "react";
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";
import Social from "../../components/Social";
import ImageAurelie from "../../components/images/ImageAurelie";

const PortfolioMariageAurelie = () => {
  return (
    <div className="portfolio">
      <Navigation />
      <h3>Aurelie & Tim</h3>
      <h4>Un mariage scellé par l’Amour familial</h4>
      <p className="text-portf">
      La cérémonie civile <span className="color-texte">d’Aurélie et Tim</span> aura été particulièrement éprouvante pour le papa de la mariée puisque c’est lui-même, en qualité d’élu, qui les a mariés.<br /><br /> Cerise sur le gâteau : c’était une surprise, les mariés n’étaient même pas au courant !<br />
      L’expérience s’est reproduite au moment de la <span className="color-texte">cérémonie laïque,</span> mais cette fois-ci avec la marraine d’Aurélie comme <span className="color-texte">officiante de cérémonie.</span><br /><br /> Pour couronner le tout, nous avons eu la chance d’écouter les belles mélodies du <span className="color-texte">saxophone de l’oncle Robert.</span>
      <br /><span className="color-texte">De grands moments</span> de solennité et de bonheur unique dont chacun se souviendra longtemps.
      <br /><br />Ah oui, j’allais oublier…la coiffeuse de la mariée était aussi de la famille.
      
      </p>
      <ImageAurelie />
      <Social />
      <Footer />
    </div>
  );
};

export default PortfolioMariageAurelie;
