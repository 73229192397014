import React from "react";
import Navigation from "../components/Navigation";
import Logo from "../components/Logo";
import FormContact from "../components/FormContact";
import CoordLucie from "../components/CoordLucie";
import Social from "../components/Social";
import Footer from "../components/Footer";
import { motion } from "framer-motion";

const Contact = () => {
  return (
    <motion.div
      className="contact"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0, display: "none", transition: { duration: 2 } }}
    >
      <div className="header">
        <div className="header-inside">
          <Navigation />
          <Logo />
          <h1>Contact</h1>
        </div>
      </div>
      <h2 className="titre-pres">Des Jours Heureux</h2>
      <div className="contact-lucie">
        <CoordLucie />
        <FormContact />
      </div>
      <Social />
      <Footer />
    </motion.div>
  );
};

export default Contact;
