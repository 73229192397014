import React from 'react';
import Navigation from '../../components/Navigation';
import ImageBaptemeIrene from '../../components/images/ImageBaptemeIrene';

const PortoMariageLucie = () => {
    return (
        <div className="portfolio">
          <Navigation />
          <h3>Baptême Irene</h3>
          <h4>Recette pour reussir un joli baptême</h4>
      <p className="text-portf-irene">
      
      A la naissance, donnez à votre petite fille <span className="color-texte">un doux prénom</span> empreint de votre histoire familiale. <br />
      Laisser <span className="color-texte">grandir</span> pendant 9 bons mois. <br />
      Le 19 juin 2021, mélangez <span className="color-texte">la famille et les proches</span> dans le jardin de la marraine d’Irène. <br />
      Saupoudrez-les de l’air ambiant <span className="color-texte">printanier.</span> <br />
      Agrémentez le tout d’une <span className="color-texte">jolie décoration</span> imaginée par Christelle Anquetin Reuter <span className="color-text">(CMTendances).</span><br />
      Ajoutez <span className="color-texte">une tonne d’Amour</span> autour de la petite Irène.<br />
      Incorporez un <span className="color-texte">buffet dînatoire</span> préparé avec soin par Les <span className="color-texte">Délices de Flo.</span><br />
      Faites cuire à 25°c sous <span className="color-texte">le soleil </span>pendant 3 heures.<br />
      Pendant la cuisson, <span className="color-texte">chantez une mélodie</span> qui parle de bonheur.<br />
      Immortalisez cette belle journée à travers l’objectif de <span className="color-text">Marie Amiel (M.A petite chambre noire).</span><br />
      Il ne vous reste plus qu’à <span className="color-texte">savourer !</span>
      
      </p>
          <ImageBaptemeIrene />
        </div>
    );
};

export default PortoMariageLucie;