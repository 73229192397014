import React from "react";
import Gallery from "react-photo-gallery";
import Measure from "react-measure";
import Lightbox from "react-images";

// Ajouter ici les photos pour le portfolio

const photos = [
  {
    src: "https://i.ibb.co/ns9YFfS/40.webp",
    width: 2,
    height: 3,
  },
  {
    src: "https://i.ibb.co/8XCgKg5/39.webp",
    width: 3,
    height: 2,
  },
  {
    src: "https://i.ibb.co/52f7Dtt/38.webp",
    width: 3,
    height: 2,
  },
  {
    src: "https://i.ibb.co/qY1fGkC/37.webp",
    width: 3,
    height: 2,
  },
  {
    src: "https://i.ibb.co/X2nJmQ5/36.webp",
    width: 3,
    height: 2,
  },
  {
    src: "https://i.ibb.co/7bm67Wh/34.webp",
    width: 4,
    height:3,
  },
  {
    src: "https://i.ibb.co/NpbrM4P/35.webp",
    width: 2,
    height: 3,
  },
  {
    src: "https://i.ibb.co/1v47pLp/33.webp",
    width: 2,
    height: 3,
  },
  {
    src: "https://i.ibb.co/VS83kBS/32.webp",
    width: 2,
    height: 3,
  },
  {
    src: "https://i.ibb.co/Wt1s0ns/31.webp",
    width: 2,
    height: 3,
  },
  {
    src: "https://i.ibb.co/rmpb9SP/30.webp",
    width: 2,
    height: 3,
  },
  {
    src: "https://i.ibb.co/99fSdYq/29.webp",
    width: 3,
    height: 2,
  },
  {
    src: "https://i.ibb.co/kK4rDG5/28.webp",
    width: 2,
    height: 3,
  },
  {
    src: "https://i.ibb.co/ypSKYyv/27.webp",
    width: 4,
    height: 5,
  },
  {
    src: "https://i.ibb.co/bWZRwVg/26.webp",
    width: 2,
    height: 3,
  },
  {
    src: "https://i.ibb.co/ZBs3dtd/25.webp",
    width: 3,
    height: 2,
  },
  {
    src: "https://i.ibb.co/Lrwffx7/23.webp",
    width: 2,
    height: 3,
  },
  {
    src: "https://i.ibb.co/prMXJHn/24.webp",
    width: 2,
    height: 3,
  },
  {
    src: "https://i.ibb.co/C0Xz50S/22.webp",
    width: 3,
    height:2,
  },
  {
    src: "https://i.ibb.co/BTrMYc4/21.webp",
    width: 2,
    height: 3,
  },
  {
    src: "https://i.ibb.co/bNtttR3/20.webp",
    width: 2,
    height: 2,
  },
  {
    src: "https://i.ibb.co/Hxj245S/19.webp",
    width: 2,
    height: 3,
  },
  {
    src: "https://i.ibb.co/C5f2jTp/18.webp",
    width: 3,
    height: 2,
  },
  {
    src: "https://i.ibb.co/gyTtQJp/17.webp",
    width: 3,
    height:2,
  },
  {
    src: "https://i.ibb.co/YXSD4zG/16.webp",
    width: 2,
    height: 3,
  },
  {
    src: "https://i.ibb.co/c29HMkR/15.webp",
    width: 3,
    height: 2,
  },
  {
    src: "https://i.ibb.co/s3mjRg5/14.webp",
    width: 2,
    height: 3,
  },
  {
    src: "https://i.ibb.co/6Dcs073/13.webp",
    width: 4,
    height: 3,
  },
  {
    src: "https://i.ibb.co/yXhh0k5/11.webp",
    width: 2,
    height: 3,
  },
  {
    src: "https://i.ibb.co/YLD5j7H/12.webp",
    width: 2,
    height: 3,
  },
  {
    src: "https://i.ibb.co/zRX7Ggz/9.webp",
    width: 2,
    height: 3,
  },
  {
    src: "https://i.ibb.co/vDkd5vF/10.webp",
    width: 2,
    height: 3,
  },
  {
    src: "https://i.ibb.co/kq5DM13/9-bis.webp",
    width: 3,
    height: 2,
  },
  {
    src: "https://i.ibb.co/xmDDfhm/8.webp",
    width: 2,
    height: 3,
  },
  {
    src: "https://i.ibb.co/PN9PDRP/7bis.webp",
    width: 2,
    height: 3,
  },
  {
    src: "https://i.ibb.co/nLnTVQ6/7.webp",
    width: 3,
    height: 2,
  },
  {
    src: "https://i.ibb.co/1Zjz6gp/6.webp",
    width: 3,
    height: 2,
  },
  {
    src: "https://i.ibb.co/wJJ0YGs/5.webp",
    width: 2,
    height: 2,
  },
  {
    src: "https://i.ibb.co/fCY9JdY/3.webp",
    width: 2,
    height: 3,
  },
  {
    src: "https://i.ibb.co/hCLZYkR/4.webp",
    width: 2,
    height: 3,
  },
  {
    src: "https://i.ibb.co/dkMbW8H/1.webp",
    width: 2,
    height: 3,
  },
  {
    src: "https://i.ibb.co/ZTd29kh/2.webp",
    width: 2,
    height: 3,
  },
];

class ImageMariageLJ extends React.Component {
  constructor() {
    super();
    this.state = { currentImage: 0 };
    this.closeLightbox = this.closeLightbox.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
  }
  openLightbox(event, obj) {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true,
    });
  }
  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false,
    });
  }
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1,
    });
  }
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1,
    });
  }
  render() {
    const width = this.state.width;
    return (
      <Measure
        bounds
        onResize={(contentRect) =>
          this.setState({ width: contentRect.bounds.width })
        }
      >
        {({ measureRef }) => {
          if (width < 1) {
            return <div ref={measureRef} />;
          }
          let columns = 2;
          if (width >= 480) {
            columns = 3;
          }
          if (width >= 1024) {
            columns = 3;
          }
          if (width >= 1824) {
            columns = 4;
          }
          return (
            <div
              className="gallery"
              ref={measureRef}
              style={{ height: "80vh", overflow: "scroll" }}
            >
              <Gallery
                className="image-gallery"
                photos={photos}
                columns={columns}
                onClick={this.openLightbox}
              />
              <Lightbox
                className="lightbox"
                images={photos}
                onClose={this.closeLightbox}
                onClickPrev={this.gotoPrevious}
                onClickNext={this.gotoNext}
                currentImage={this.state.currentImage}
                isOpen={this.state.lightboxIsOpen}
              />
            </div>
          );
        }}
      </Measure>
    );
  }
}
export default ImageMariageLJ;










































