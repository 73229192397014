import React from "react";
import Navigation from "../../components/Navigation";
import ImageAnniversaireSophie from "../../components/images/ImageAnniversaireSophie";

const PortfolioAnniversaireSophie = () => {
  return (
    <div className="portfolio">
      <Navigation />
      <h3>Anniversaire Sophie & Julien</h3>
      <h4>40 ans x 2 = 40 ans à 2</h4>
      <p className="text-portf">
        Sophie & Julien ont fêté leurs <span className="color-texte">anniversaires</span> dans leur belle demeure.
        Une bâtisse rénovée avec beaucoup de goût et de raffinement. Un endroit
        où je me suis sentie à l’aise pour organiser leur soirée. Une soirée
        placée sous le thème de <span className="color-texte">la gourmandise</span> avec les petits desserts préparés
        par <span className='color-texte'>Cooking Cakes</span> à Montady. Merci pour leur accueil, leur gentillesse
        et leur <span className='color-texte'>confiance !</span>
      </p>
      <ImageAnniversaireSophie />
    </div>
  );
};

export default PortfolioAnniversaireSophie;
