import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { FaCaretDown } from "@react-icons/all-files/fa/FaCaretDown";

const EvenementType = () => {
  const [displayMariage, setDisplayMariage] = useState(false);
  const [displayBapteme, setDisplayBapteme] = useState(false);
  const [displayEvent, setDisplayEvent] = useState(false);

  return (
    <div className="btn-event">
      <div className="mariage type">
        <h3
          onClick={() =>
            setDisplayMariage(displayMariage === false ? true : false)
          }
        >
          Mariages{" "}
          <span className="icon">
            <FaCaretDown />
          </span>
        </h3>

        {displayMariage === true ? (
          <div>
            <p>
              {" "}
              Vous avez décidés de vous marier et vous devez vous lancer dans
              les préparatifs.
              <br /> <br /> Tout un tas de questions se pose à vous : Par où
              commencer ? Comment gérer le compte à rebours ? Quels prestataires
              choisir ? Ces questions sont tout à fait normales et je peux vous
              aider à y répondre. <br />
              <br /> Selon vos attentes et vos envies, je vous propose
              différentes formules d’accompagnement qui vont du simple conseil à
              l’organisation complète de votre mariage.
            </p>
            <div className="btn-prestation-mariage">
              <NavLink to="/prestation2">Voir plus</NavLink>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="bapteme type">
        <h3
          onClick={() =>
            setDisplayBapteme(displayBapteme === false ? true : false)
          }
        >
          Baptêmes{" "}
          <span className="icon">
            <FaCaretDown />
          </span>
        </h3>

        {displayBapteme === true ? (
          <div>
            <p>
              {" "}
              Votre enfant est né et vous souhaitez célébrer son arrivée par un
              baptême religieux ou civil. Quelque soit votre choix, après la
              cérémonie place à la fête !
              <br />
              <br /> Que ce soit à la maison ou dans un lieu de réception, je
              vous aiderai à organiser les festivités qui suivront la
              cérémonie : recherche de prestataires, rétro planning,
              décoration…et ma présence le jour J si vous le souhaitez. Je peux
              également intervenir dans la préparation de la cérémonie avec la
              réception et la mise en place des fleurs à l’Eglise par exemple.
              Prestation sur devis selon vos besoins
            </p>
            <div className="btn-prestation-mariage">
              <NavLink to="/contact">Me contacter</NavLink>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="event type">
        <h3
          onClick={() => setDisplayEvent(displayEvent === false ? true : false)}
        >
          Autres Evènements{" "}
          <span className="icon">
            <FaCaretDown />
          </span>
        </h3>

        {displayEvent === true ? (
          <div>
            <p>
              {" "}
              Pour tout autre évènement (anniversaire, PACS, départ à la
              retraite, fête surprise etc.), vous pouvez également avoir besoin
              d’un organisateur d’évènements.
              <br />
              <br /> Je vous propose une première rencontre pour connaître votre
              projet. Je reviendrai ensuite vers vous avec des propositions de
              prestations, un budget prévisionnel et un devis de mon
              intervention.
            </p>
            <div className="btn-prestation-mariage">
              <NavLink to="/contact">Me contacter</NavLink>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default EvenementType;
