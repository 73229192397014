import React from 'react';
import { FaHeart } from "@react-icons/all-files/fa/FaHeart";
import { NavLink } from 'react-router-dom'

const Footer = () => {
    return (
        <div className="footer">
            <p>Lucie MANRESA</p>
            <p>© Des Jours Heureux | Crée avec <FaHeart className="heart" /> par <br/><span>Jean-Roch TOMASO</span></p>
            <div className="link-mention">
                <NavLink to="/mention">Mentions légales | </NavLink>
                <NavLink to="/cgv">CGV</NavLink>
            </div>
        </div>
    );
};

export default Footer;