import { React } from "react";
import Navigation from "../../components/Navigation";
import ImageLucie from "../../components/images/ImageLucie";

const PortfolioAnniversaireLucie = () => {

 

  return (
    <div className="portfolio">
      <Navigation />
      <h3>Un petit air marin</h3>
      <p className="text-portf-irene">
        Un anniversaire qui annonce le début des vacances avec sa{" "}
        <span className="color-texte">déco marine.</span>
        <br />
        Du bois flotté, quelques filets de pêche, du sable et{" "}
        <span className="color-texte">le tour était joué.</span>
      </p>
      <ImageLucie />
    </div>
  );
};

export default PortfolioAnniversaireLucie;
