import React from "react";
import Gallery from "react-photo-gallery";
import Measure from "react-measure";
import Lightbox from "react-images";

// Ajouter ici les photos pour le portfolio

const photos = [
  {
      src: "https://i.ibb.co/XD2bKQn/Mariage-Ce-cile-Patrick.webp",
      width: 2,
      height: 3
  },
  {
    src: "https://i.ibb.co/Ng0z6r4/APE00m-GCJP8-Jf-Autd8911t9-gq-B32z6t-Sv-Xvrl-Tx-Mm-EDm3-J9-Fo-W0-Cdr-34-Qu-C5sfzx-O1mk-Oeo-X50a-TIb.webp",
    width: 2,
    height: 3
  },
  {
    src: "https://i.ibb.co/q5S0f53/AOx-FAk-Ihq-NQ1dm-WGWW-Y4-Bu-Cgt-Wf3-A-0c5p77l3y-Fs-YVn6-P6-Ck-BMNQVDu-H5736xu-AIFhu44egz-Fr-V-F-L0b.webp",
    width: 3,
    height:2
  },
  {
    src: "https://i.ibb.co/JQSyG8V/AMu-S8x-Tj-JDc-L7f-Ajv-Ollt3h-D60d-f-QXFz1pn6o-VAZ2-CQgc1-0z-G5-Un-Fwk-n-U115-O3-EGy-U-to-DDv8rjr-We.webp",
    width: 3,
    height: 2
  },
  {
    src: "https://i.ibb.co/SwWkF7c/AMt-YUWc3-w-GZnyim-G-4-Ylr-Ywb5-Cp-M7g-Twmgv-B5-Qee-BKg-NUR3q-IRFid5-QWFZx-G3-D4-Gy-E9ng89-UUO3k-SSG.webp",
    width: 3,
    height: 2
  },
  {
    src: "https://i.ibb.co/1zbSZnr/AMts-XRMGz1-HNfe-BXz-AJK9t-S6d-g-YYumxnh-Us-L58b-N-If-C-vu-Ijjy-Pr09sh-Sfsyk-B2u1f-DWIIp3-ZM-b-U9-K4.webp",
    width: 4,
    height: 3
  },
  {
    src: "https://i.ibb.co/Sm5YRdM/AK3-T4-Or-Ev1-NYRQqd-H6-En1xxp-WYc-Kf-J3emr-JXDkmm3sn9f-Fkj-El-VLJYl1f-0-ILq13-6-Gx-Rd28-VJg-OQOpoe.webp",
    width: 3,
    height:2
  },
  {
    src: "https://i.ibb.co/gwYYYMJ/AJXKe-JOLulpc-EYi1o-Eh-C60m-Bb-Ab-Mwf-Z7zkaq4-VI-H-Bxb-Dp-ARew-Et-BHL4adg-g-L-D7-k-BFLDp-CWe-NE491-I.webp",
    width: 1,
    height:1
  },
  {
    src: "https://i.ibb.co/sKggXmW/AJpn-RA7-AS-Frh-QLQ6-BQ8e-CRK-XHIc-Mvnh9n-h-FXv-C6-Llr-Fy-WUCJLt3-SDnl9gq7q-LKa3pu6u-Rfc-ATZ4-D9bri2.webp",
    width: 3,
    height:2
  },
  {
    src: "https://i.ibb.co/Xbbv6fM/AJnt5-W9e18e-LDq-W6vd-VFBSucx-TQT3-YAdj-Hf-Ht-Ow-Az11z-Qf-C1-U-2x-2u-E2-MOUStvrghu-OHi-v4-Ht6-X-nut9.webp",
    width: 3,
    height:2
  },
  {
    src: "https://i.ibb.co/FgPrz5V/AIWq6s-JJfo-WYB7-A4-PGFTj1kh-YHnti-DRl-Y2-Rro-TM06i5e-De-S3g-UIEr-Hr-YBf-Ms-DGh-UH5-Qw-LRv7d-Cialach.webp",
    width: 3,
    height:2
  },
  {
    src: "https://i.ibb.co/2KK7RFw/AI3abk-E9j5-JU7v-Z6-FUo9-03n-Cgo-Dt38-WLSCs02y7-FFQqz9s-KKul-Y5-LHum-CBFik6-y-Slt-Gi8ic-ROTUBw-G3b7w.webp",
    width: 3,
    height:2
  },
  {
    src: "https://i.ibb.co/7rxMzSZ/AHWt-Q0j-HFuu-ZDaz-Olq93-W7-Gj-H32c-YRq-Gta3j-Ku9-UIq-Xyqs-VSGCxh-FZHl-Vloed-C5w-Mkku64oo-Tt-RCx-Osp.webp",
    width: 3,
    height:2
  },
  {
    src: "https://i.ibb.co/4fC7WpG/AFTzya-OU6-XSZv-ZXhjjylye-Ay-LQDGAWBX01-Ni-Vr-Yn-Ib-Qe-Oeqhz-Lagnaz-P9-Y50-XEAYh2-zp-Gm-el-Ed-Z-d-Vc.webp",
    width: 3,
    height:2
  },
  {
    src: "https://i.ibb.co/QDSkmFr/AFp3o-Kysdu-FJq-Fv-UK6kq-KDk-KT-a-VRiw-W68gavz4-Xi-YKqp-s-SAOBEhlbd-QN9x2-Y1-GRk3h-SUFc-E-gx-Pf5k5vk.webp",
    width: 3,
    height:2
  },
  {
    src: "https://i.ibb.co/X33yDmT/AFJq-O5-Iw-Vjj7-Yb-Lby5-S8-En8-RGlftn-BX0-CQBk-QRc-Ew9m-W8-a-DHh7i-YGAGTLk-Yv-Xh-Maq-A5c1-TK4-QXAp-R.webp",
    width: 3,
    height:2
  },
  {
    src: "https://i.ibb.co/9gDkSn6/AFCyhxq-RB5t-UMWi-BDr-G2-FGw-D-ueiom-RGqt-Lwi-WUbxal-Nw-Iit-FZMro-L1i-VNWt-JVOMnmv-CFUm-U3k-VAKl-C-h.webp",
    width: 3,
    height:2
  },
  {
    src: "https://i.ibb.co/NVZ7445/AF6wc-Q-mob2kl-GXAe-YA5v-B-2c-HVz-C3e-m5-KN61i-NR1-O1j-Ug-Hb-38u-Gd80o-Oa-ICe-TLDYpbl-Ofxc1yi-SO3-Wh.webp",
    width: 2,
    height:2
  },
  {
    src: "https://i.ibb.co/LSDfGDS/AEk-Qtr-Iavc-Is6k-b-BWx-Zr-Bca-Wq-Uu-CR7vq-RF50-Sn95e0-Dg-Ta-Rv-Hy46ruz-XYb6-Sij-Gw-QFy3s-Qj-J-w-Wg.webp",
    width: 3,
    height:2
  },
  {
    src: "https://i.ibb.co/1zCjP8t/AEGx9ltt8hrwxjf-LGiv-EIhb-Xu-H3lp2-Ud-Avf1xq-RTOw-TIMN3-Av-JU3y-Ksiw7r-JD5m-Rpxp-K86-HIK4-DAHs-Pb-Cm.webp",
    width: 3,
    height:2
  },
  {
    src: "https://i.ibb.co/WzQ7sP6/ADZi-UXMYb-PG7-V1u87-Keb6-Auy-Khv-Glm-LTorn-Jh-SBs-Zl-Vuy8dpo0-QBHb-KQ-Fud-Qg8kp-Gk1-Z6-EVq-Gh-Go-WD.webp",
    width: 2,
    height:2
  },
  {
    src: "https://i.ibb.co/JkxHW3M/ADj-WL-ds-Zwl-f-RR13-QI5pogx8i-OR3n0bm0-X8-Buk-AV2f-T2ntu-G25-Kz-Bf-MXu-S6-Pvx-K25k-MYE0-XPfwp127-ZI.webp",
    width: 3,
    height:2
  },
  {
    src: "https://i.ibb.co/3NdFT3s/ADCBT-gpi-Gb5s-Y-b-Rhb-NMQv-A-ENc-Uof-TSx-UKp3lm0rq28-SDFVHVWpa-EMS-fn-Qbq-I7-YC32uwgo-I7lt-KU2j-EYB.webp",
    width: 3,
    height:3
  },
  {
    src: "https://i.ibb.co/MhHCBLC/AD6elee-NNa-O9-Tst0-ir-R-STly-QEIyj-M2443-Jof84vyrcj-UWKw85re-z-Zd-Xqi7g-P22-Zn-Qfyb6a-X7-z4a7-ZRi-K.webp",
    width: 3,
    height:2
  },
  {
    src: "https://i.ibb.co/LrGyQKL/ACU9c05-Ba-Ym-MQn-Bj-Nik-JWHibnt8o-Xy-PYn-Ly-c-Aa-JZh3-H8v-Zd-Tz-Rr6y9-Gw-Kjy-BT9zbcii-TXo-IPo-DUxz.webp",
    width: 3,
    height:2
  },
  {
    src: "https://i.ibb.co/HhLQLh1/AAQMHBz-NKAuu-Weeword-Hzw-BGf-Q72t3u-Ue-j6-Ay-MFOcy-Gg8-Pt-Kp4a-Hfg5akby-Po8-NSa5-Dvc4k-CLCQf-UV-1gx.webp",
    width: 3,
    height:2
  },
  {
    src: "https://i.ibb.co/p28J9Hh/AAla-Bd-U3t-JNSHUjw4-cz-PXEB88v-Jz-QLP44ce-Gu-SU1-J-DBUUco5-I7-Ii-m7ogw-Vzc4y-Ka2-BRtlsy-I7-C1s-Evm.webp",
    width: 3,
    height:2
  },
  {
    src: "https://i.ibb.co/yq1L4X0/AA-Vac9-FLq-DZ4w-TD4-NWz6-GXptps-J7t-Gb8-X9-MFi-g-XL-OFmc-Tp-JSGj-Us-W48t-El-ND9-UGa-LYu-J1-MY6t2-Ah.webp",
    width: 3,
    height:2
  },
  {
    src: "https://i.ibb.co/TqJhs12/AAdy-Yp-DWr5ld1zgpf-Qzxu-Ojr-I5-Abbgo-K6-T67t-KLQq-Gi-WDMHAQJRt-SOJr-Iwg-Lemf3y-M7ff-Zb-Ajg-WQRX6f-Y.webp",
    width: 3,
    height:3
  },
];


class ImageMariageCeline extends React.Component {
  constructor() {
    super();
    this.state = { currentImage: 0 };
    this.closeLightbox = this.closeLightbox.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
  }
  openLightbox(event, obj) {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true
    });
  }
  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false
    });
  }
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1
    });
  }
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1
    });
  }
  render() {
    const width = this.state.width;
    return (
      <Measure
        bounds
        onResize={contentRect =>
          this.setState({ width: contentRect.bounds.width })
        }
      >
        {({ measureRef }) => {
          if (width < 1) {
            return <div ref={measureRef} />;
          }
          let columns = 2;
          if (width >= 480) {
            columns = 3;
          }
          if (width >= 1024) {
            columns = 3;
          }
          if (width >= 1824) {
            columns = 4;
          }
          return (
            <div className="gallery" ref={measureRef} style={{ height: "80vh", overflow: "scroll" }}>
              <Gallery
                className="image-gallery"
                photos={photos}
                columns={columns}
                onClick={this.openLightbox}
              />
              <Lightbox
              className="lightbox"
                images={photos}
                onClose={this.closeLightbox}
                onClickPrev={this.gotoPrevious}
                onClickNext={this.gotoNext}
                currentImage={this.state.currentImage}
                isOpen={this.state.lightboxIsOpen}
              />
            </div>
          );
        }}
      </Measure>
    );
  }
}
export default ImageMariageCeline;






























