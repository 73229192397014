import React, { useState } from "react";
import Footer from "../components/Footer";
import Logo from "../components/Logo";
import Navigation from "../components/Navigation";
import Social from "../components/Social";

import { FaCaretDown } from "@react-icons/all-files/fa/FaCaretDown";
import { NavLink } from "react-router-dom";
const PrestationMariage = () => {
  const [displayFormule1, setDisplayFormule1] = useState(false);
  const [displayFormule2, setDisplayFormule2] = useState(false);
  const [displayFormule3, setDisplayFormule3] = useState(false);
  const [displayFormule4, setDisplayFormule4] = useState(false);
  const [displayFormule5, setDisplayFormule5] = useState(false);

  return (
    <div className="presta-mariage">
      <div className="header">
        <div className="header-inside">
          <Navigation />
          <Logo />
          <h1>Nos formules mariage</h1>
        </div>
      </div>

      <div className="demande">
        <h2>Demande en mariage</h2>
        <div className="demande-box">
          <div className="texte-mariage">
            <p>
              <span className="texte-color">Ça y est !</span> Vous vous êtes
              décidés à sauter le pas et à faire votre demande en mariage ! Vous
              avez très envie de surprendre votre moitié mais vous
              <span className="texte-color">
                {" "}
                manquez de temps ou d’imagination.
              </span>{" "}
              Nous verrons
              <span className="texte-color"> ensemble</span> quel scénario
              serait le plus adapté à la personnalité de votre couple. Je saurai
              rester discrète voire{" "}
              <span className="texte-color">confidentielle…</span>
            </p>
            <p className="budget">
              *Budget à définir ensemble <br /> selon vos moyens et vos envies
            </p>
          </div>
          <img
            className="mariage-img"
            src="https://i.ibb.co/dc02vxd/bague-13-11zon.jpg"
            alt=""
          />
        </div>
      </div>

      <div className="mariage">
        <h2>Mariage</h2>
        <p className="texte-presentation-mariage">
          Vous avez décidés de vous <span className="texte-color">marier</span>{" "}
          et vous devez vous lancer dans les préparatifs. Tout{" "}
          <span className="texte-color">
            un tas de questions se pose à vous :
          </span>{" "}
          Par où commencer ? Comment gérer le{" "}
          <span className="texte-color">compte à rebours ?</span> Quels
          prestataires choisir ? Ces questions sont tout à fait normales et je
          peux <span className="texte-color">vous aider à y répondre. </span>
          Selon vos attentes et <span className="texte-color">
            vos envies,
          </span>{" "}
          je vous propose différentes formules
          <span className="texte-color"> d’accompagnement</span> qui vont du
          simple conseil à l’organisation complète de votre mariage.
        </p>
      </div>
      <div className="formule3">
        <h3
          onClick={() =>
            setDisplayFormule3(displayFormule3 === false ? true : false)
          }
        >
          Conseil
          <span className="icon">
            <FaCaretDown />
          </span>
        </h3>

        {displayFormule3 === true ? (
          <div>
            <p>
              Rencontrons-nous pour parler de votre mariage sur 3 RDV : <br />
              <br />
              Un premier RDV (12 à 18 mois avant le mariage) pour vous
              conseiller sur les grandes lignes de l’organisation de votre
              mariage. <br />
              <br />
              Un second RDV (6 mois à un an avant) au cours duquel nous ferons
              le point sur vos avancées et vos interrogations.
              <br />
              <br /> Un troisième RDV (2 mois avant) pour vous guider dans la
              dernière ligne droite et régler tous les petits détails. <br />
              <br />
              
            </p>

            <div className="btn-prestation-mariage">
              <NavLink to="/contact">Contactez moi</NavLink>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="formule1">
        <h3
          onClick={() =>
            setDisplayFormule1(displayFormule1 === false ? true : false)
          }
        >
          Organisation partielle{" "}
          <span className="icon">
            <FaCaretDown />
          </span>
        </h3>

        {displayFormule1 === true ? (
          <div>
            <p>
              {" "}
              Vous vous êtes lancés dans l’organisation de votre mariage depuis
              plusieurs semaines, le temps passe et vous vous rendez compte
              qu’il est difficile de tout combiner : vie privée, vie
              professionnelle… et les préparatifs. <br />
              <br /> Ou encore vous avez déjà réservé certains prestataires mais
              vous manquez d’idées ou de bonnes adresses pour avancer. <br />
              <br /> Et bien c’est à ce moment-là que j’interviens !
              <br /> Confiez-moi une partie de l’organisation et je m’adapterai
              à votre projet de mariage.
              <br />
              <br />{" "}
            </p>
            <div className="btn-prestation-mariage">
              <NavLink to="/contact">Contactez moi</NavLink>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="formule2">
        <h3
          onClick={() =>
            setDisplayFormule2(displayFormule2 === false ? true : false)
          }
        >
          Organisation de A à Z{" "}
          <span className="icon">
            <FaCaretDown />
          </span>
        </h3>

        {displayFormule2 === true ? (
          <div>
            <p>
              Il s’agit de la formule la plus complète. <br /> Du jour de notre
              rencontre jusqu’au jour de votre mariage, je serai présente à vos
              côtés pour vous accompagner et vous conseiller à chaque étape :
              <br />
              <br /> Un premier RDV pour faire connaissance, cibler vos envies,
              définir votre budget prévisionnel. <br />
              <br /> Visites de lieux, propositions/ rencontres de prestataires
              (traiteur, photographe etc) <br />
              <br /> Gestion des demandes de devis et des prises de RDV
              Réalisation d’un rétro planning de l’année, des préparatifs ainsi
              qu’un rétro planning du jour J <br /> RDV réguliers pour faire le
              point ensemble. <br />
              <br /> Ma présence (si vous le souhaitez) le jour de votre mariage
              ainsi que la veille, pour superviser la mise en place, coordonner
              les prestataires et gérer les éventuels imprévus… <br />
              <br />
              Avec cette formule, vous pourrez ainsi préparer votre mariage
              sereinement et profiter pleinement le jour J.
              <br />
              <br />
            </p>
            <div className="btn-prestation-mariage">
              <NavLink to="/contact">Contactez moi</NavLink>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="formule2">
        <h3
          onClick={() =>
            setDisplayFormule4(displayFormule4 === false ? true : false)
          }
        >
          Formule recherche de prestataires{" "}
          <span className="icon">
            <FaCaretDown />
          </span>
        </h3>

        {displayFormule4 === true ? (
          <div>
            <p>
              Les préparatifs commencent par la recherche de prestataires (lieu
              de réception, traiteur, photographe, DJ…).
              <br />
              <br /> Je suis là pour vous proposer des professionnels qui
              répondront à vos envies et qui respecteront votre budget. <br />
              <br />
              Pour la réception par exemple, je vous présenterai plusieurs lieux
              que nous pourrons aller visiter ensemble. <br />
              <br />
              Je vous accompagnerai jusqu’à la signature du contrat avec le lieu
              de réception que vous aurez choisi. <br />
              <br />
            </p>
            <div className="btn-prestation-mariage">
              <NavLink to="/contact">Contactez moi</NavLink>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="formule2">
        <h3
          onClick={() =>
            setDisplayFormule5(displayFormule5 === false ? true : false)
          }
        >
          Coordination le Jour J{" "}
          <span className="icon">
            <FaCaretDown />
          </span>
        </h3>

        {displayFormule5 === true ? (
          <div>
            <p>
              Après avoir préparé votre mariage en autonomie, vous souhaiteriez
              déléguer l’organisation du jour J pour pouvoir profiter pleinement
              de votre journée. <br/><br/>Je vous invite alors à me contacter 2 mois avant
              votre mariage afin de définir ensemble le déroulement de la
              journée. <br/><br/>Cet intervalle de temps me permettra également de
              contacter tous vos prestataires et de régler les derniers détails.
              <br/><br/>Le jour venu, je serai présente à vos côtés tout au long de la
              journée (des préparatifs beauté le matin jusqu’au dessert des
              mariés le soir) pour assurer le bon déroulement de votre mariage,
              coordonner les prestataires, et parer aux imprévus. <br/><br/>Vous n’aurez
              plus qu’à profiter de votre moitié et de vos convives !
              <br />
              <br />
            </p>
            <div className="btn-prestation-mariage">
              <NavLink to="/contact">Contactez moi</NavLink>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>

      <div className="weeding-anniv">
        <h2>Anniversaire de mariage</h2>
        <div className="box-anniversary">
          <img
            className="weeding"
            src="https://i.ibb.co/H4wKyMf/demandepc-16-11zon.jpg"
            alt=""
          />
          <div className="text-anniversary">
            <p>
              Traditionnellement, chaque{" "}
              <span className="texte-color">anniversaire de mariage</span>{" "}
              correspond à un thème ou un matériau de plus en plus solide, pour
              symboliser{" "}
              <span className="texte-color">la force de l’union</span> des
              époux.
              <br />
              <br /> Qu’il soit de coton, de perle ou d’argent, cet anniversaire
              est l’occasion de se{" "}
              <span className="texte-color">
                remémorer le jour de votre mariage.
              </span>{" "}
              Pour revivre la <span className="texte-color">magie</span> de
              cette journée qui vous a unit quelques années auparavant, vous
              pouvez faire le choix de passer tout simplement un moment à deux
              ou bien de vous entourer de vos proches autour d’une{" "}
              <span className="texte-color">belle fête.</span>
              <br />
              <br /> Ce sera également l’occasion, si vous le souhaitez, de
              <span className="texte-color"> renouveler vos vœux</span> au cours
              d’une cérémonie laïque ou religieuse. Cette offre{" "}
              <span className="texte-color line-anniv">
                « Anniversaires de mariages »
              </span>{" "}
              s’adresse à ceux qui n’ont pas forcément l’énergie, le temps ou
              l’imagination nécessaire pour se lancer dans l’organisation{" "}
              <span className="texte-color">d’un tel évènement.</span>
              <br />
            </p>
          </div>
          <br />
        </div>
        <div className="orga">
          <p>
            {" "}
            Concernant mes prestations, je vous propose{" "}
            <span className="texte-color">les mêmes formules</span>{" "}
            d’accompagnements que pour un mariage : Conseil, organisation
            <span className="texte-color"> partielle</span> ou de{" "}
            <span className="texte-color">A à Z.</span>
            <br />
            <br />
          </p>
        </div>
      </div>

      <Social />
      <Footer />
    </div>
  );
};

export default PrestationMariage;
