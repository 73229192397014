import React from "react";
import Home from "../pages/Home";
import Prestations from "../pages/Prestations";
import Contact from "../pages/Contact";
import PrestationMariage from "../pages/PrestationMariage";
import Portfolio from "../pages/Portfolio";
import PortfolioMariageLucie from "../pages/Portfolio/PortfolioMariageLucie";
import PortfolioBaptemeIrene from "../pages/Portfolio/PortfolioBaptemeIrene";
import { Routes, Route, useLocation } from "react-router-dom";

import { AnimatePresence } from "framer-motion";
import PortfolioDemande1 from "../pages/Portfolio/PortfolioDemande1";
import NotFound from "../pages/NotFound";
import PortfolioMariageAurelie from "../pages/Portfolio/PortoflioMariageAurelie";
import PortfolioWa from "../pages/Portfolio/PortfolioWa";
import PortfolioJuliette from "../pages/Portfolio/PortfolioJuliette";
import Mention from "../pages/mention/Mention";
import Cgv from "../pages/mention/Cgv";
import PortfolioMariageCeline from "../pages/Portfolio/PortfolioMariageCeline";
import PortfolioMariageLj from "../pages/Portfolio/PortfolioMariageLj";
import PortfolioMariageVanessa from "../pages/Portfolio/PortoflioMariageVanessa";
import PortfolioAnniversaireLucie from "../pages/Portfolio/PortfolioAnniversaireLucie";
import PortfolioAnniversaireSophie from "../pages/Portfolio/PortfolioAnniversaireSophie";
import PortfolioMariageMonika from "../pages/Portfolio/PortfolioMariageMonika";
import Prestation2 from "../pages/Prestation2";

const AnimatedRoute = () => {
  const location = useLocation();

  return (

    // Liste des routes animé avec FRAMER MOTION

      <AnimatePresence>
    <Routes location={location} key={location.pathname}>
      <Route path="/" element={<Home />} />
      <Route path="/prestation" element={<Prestations />} />
      <Route path="/prestation2" element={<Prestation2 />} />
      <Route path="/portfolio" element={<Portfolio />} />
      <Route path="/contact" element={<Contact />} />
      <Route path="/prestation/mariage" element={<PrestationMariage />} />
      <Route path="/portfolio/irene" element={<PortfolioBaptemeIrene />} />
      <Route path="/portfolio/lucie" element={<PortfolioMariageLucie />} />
      <Route path="/portfolio/charlene" element={<PortfolioDemande1 />} />
      <Route path="/portfolio/aurelie" element={<PortfolioMariageAurelie />} />
      <Route path="/portfolio/wedding-academy" element={<PortfolioWa />} />
      <Route path="/portfolio/juliette" element={<PortfolioJuliette />} />
      <Route path="/portfolio/celine" element={<PortfolioMariageCeline />} />
      <Route path="/portfolio/l&j" element={<PortfolioMariageLj />} />
      <Route path="/portfolio/v&f" element={<PortfolioMariageVanessa />} />
      <Route path="/portfolio/anniv&l" element={<PortfolioAnniversaireLucie />} />
      <Route path="/portfolio/anniv&s" element={<PortfolioAnniversaireSophie />} />
      <Route path="/portfolio/m&m" element={<PortfolioMariageMonika />} />
      <Route path="/mention" element={<Mention />} />
      <Route path="cgv" element={<Cgv />} />
      <Route path="*" element={<NotFound />} />
    </Routes>
    </AnimatePresence>
  );
};

export default AnimatedRoute;
