import React from "react";
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";
import Social from "../../components/Social";
import ImageDemande1 from "../../components/images/ImageDemande1";

const PortfolioDemande1 = () => {
  return (
    <div className="portfolio">
      <Navigation />
      <h3>Charlène et Jérémy</h3>
      <h4>La corde au cou</h4>
      <p className="text-portf">
        Après <span className="color-texte">10 ans d’Amour,</span> une passion commune unissait ces deux amoureux.<br/>
        C’est donc tout naturellement que Jérémy avait choisi de faire sa
        demande sur ce lieu d’escalade : <span className="color-texte">Notre Dame du Cros.</span><br/><br/> Une fois tout en
        haut, ce lieu leur offrirait un beau panorama, symbole de <span className="color-texte">grandeur et
        d’avenir.</span> Une vue sur une perspective lointaine.<br/><br/> Une <span className="color-texte">bande de copains </span>
        grimpeurs étaient venus lui prêter main forte pour l’occasion :
        installer la corde, mettre en place des roses le long de la voie,
        équiper Charlène à son arrivée et l’assurer jusqu’en haut. <br/><br/>Jérémy avait
        demandé à <span className="color-texte">Charlène</span> de le rejoindre au spot d’escalade après son travail
        pour grimper avec des amis. Au pied de la falaise, <span className="color-texte">une rose attendait </span>
        Charlène. Puis une seconde. Une troisième. A chaque piton, une
        rose…jusqu’en haut de la falaise où <span className="color-texte">Jérémy l’attendait…</span>
      </p>
      <ImageDemande1 />
      <Social />
      <Footer />
    </div>
  );
};

export default PortfolioDemande1;
