import React from "react";
import { FaFacebookF } from "@react-icons/all-files/fa/FaFacebookF";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import logo from "../assets/_LOGO_SVG/logoweb.webp";
import { NavLink } from "react-router-dom";
import Zoom from 'react-reveal/Zoom';


const Social = () => {
  return (
    <div className="social">
      <div className="text-social">
        <p>Suivez-moi sur les réseaux sociaux ! </p>
      </div>
      <Zoom>
      <div className="icon-facebook">
        <FaFacebookF className="icon facebook" />
        <a className="link-social" target="_blank" rel="noreferrer noopener" href="https://www.facebook.com/weddingplannerbeziers">
          Suivre
        </a>
      </div>
      </Zoom>
      <Zoom>
      <div className="icon-instagram">
        <FaInstagram className="icon instagram" />
        <a className="link-social" target="_blank" rel="noreferrer noopener" href="https://instagram.com/wedding_planner_beziers?igshid=YTM0ZjI4ZDI=">
          Suivre
        </a>
      </div>
      </Zoom>
      <NavLink className="link-home" to="/">
      <img className="logo-social" src={logo} alt="" />
      </NavLink>
    </div>
  );
};

export default Social;
