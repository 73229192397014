import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Fade from "react-reveal/Fade";

import { FaCaretDown } from "@react-icons/all-files/fa/FaCaretDown";
import { FaCaretUp } from "@react-icons/all-files/fa/FaCaretUp";

const PrestaPc = () => {
  const [displayFormule1, setDisplayFormule1] = useState(false);
  const [displayFormule2, setDisplayFormule2] = useState(false);
  const [displayFormule3, setDisplayFormule3] = useState(false);
  const [displayFormule4, setDisplayFormule4] = useState(false);
  const [displayFormule5, setDisplayFormule5] = useState(false);

  return (
    <div className="presta-pc">
      <div className="text-prestation-top">
        <p>
          Découvrez les différentes formules d’accompagnement pour les mariages.{" "}
        </p>
      </div>
      <div className="demande">
        <p>
          <h3>Demande en mariage</h3>
          <span className="texte-color">Ça y est !</span> Vous vous êtes décidés
          à sauter le pas et à faire votre demande en mariage ! <br />
          <br />
          Vous avez très envie de surprendre votre moitié mais vous
          <span className="texte-color">
            {" "}
            manquez de temps ou d’imagination.
          </span>{" "}
          Nous verrons
          <span className="texte-color"> ensemble</span> quel scénario serait le
          plus adapté à la personnalité de votre couple. <br />
          <br />
          Je saurai rester discrète voire{" "}
          <span className="texte-color">
            confidentielle… <br />
          </span>
          <span className="budget">
            *Budget à définir ensemble selon vos moyens et vos envies
          </span>
        </p>

        <Fade top>
          <img
            className="mariage-img"
            src="https://i.ibb.co/dc02vxd/bague-13-11zon.jpg"
            alt=""
          />
        </Fade>
      </div>

      <h3 className="formule-titre">Mariage</h3>
      <div className="formule">
        <div className="formule3">
          <img
            className="img-formule"
            src="https://i.ibb.co/mBp9wTF/conseil-img-14-11zon.jpg"
            alt=""
          />

          <h3>
            Formule <br />
            Conseil
          </h3>
          {displayFormule3 === true ? (
            <div>
              <p>
                <span className="texte-color">Rencontrons-nous</span> pour
                parler de votre mariage sur{" "}
                <span className="texte-color">3 RDV</span> :
                <br />
                <br />
                <div className="text-center">
                  Une première rencontre (12 à 18 mois avant le mariage) pour
                  vous conseiller sur les grandes lignes de{" "}
                  <span className="texte-color">l’organisation</span> de votre
                  mariage.
                  <br />
                  <br /> Un second RDV (6 mois à un an avant) au cours duquel
                  nous ferons le point sur vos
                  <span className="texte-color">
                    {" "}
                    avancées et vos interrogations.
                  </span>{" "}
                  <br />
                  <br />
                  Un troisième RDV (2 mois avant) pour
                  <span className="texte-color"> vous guider</span> dans la
                  dernière ligne droite et régler tous les petits détails.
                </div>{" "}
                <br />
                <br />
              </p>
              <div
                className="btn-more"
                onClick={() =>
                  setDisplayFormule3(displayFormule3 === false ? true : false)
                }
              >
                <p>Réduire</p>
                <span className="icon">
                  <FaCaretUp />
                </span>
              </div>
            </div>
          ) : (
            <div>
              <p>
                <span className="texte-color">Rencontrons-nous</span> pour
                parler de votre mariage sur{" "}
                <span className="texte-color">3 RDV</span> :
                <br />
                <br />
                Une première rencontre (12 à 18 mois avant le mariage) pour vous
                conseiller sur les grandes lignes de{" "}
                <span className="texte-color">l’organisation...</span>
              </p>
              <div
                className="btn-more"
                onClick={() =>
                  setDisplayFormule3(displayFormule3 === false ? true : false)
                }
              >
                <p>Voir plus</p>
                <span className="icon">
                  <FaCaretDown />
                </span>
              </div>
            </div>
          )}
        </div>
        <div className="formule1">
          <img
            className="img-formule"
            src="https://i.ibb.co/hd645Yw/17-7-11zon.jpg"
            alt=""
          />
          <h3>
            Organisation <br />" Partielle "
          </h3>
          {displayFormule1 === true ? (
            <div>
              <p>
                {" "}
                Vous vous êtes lancés dans{" "}
                <span className="texte-color">
                  l’organisation de votre mariage
                </span>{" "}
                depuis plusieurs semaines, le temps passe et vous vous rendez
                compte qu’il est <span className="texte-color">difficile</span>{" "}
                de tout combiner : vie privée, vie professionnelle… et les
                préparatifs. <br />
                <br /> Ou encore vous avez déjà réservé certains prestataires
                mais vous manquez d’idées ou de bonnes adresses pour avancer.{" "}
                <br />
                <br /> Et bien c’est à ce moment-là que{" "}
                <span className="texte-color">j’interviens !</span>
                <br /> Confiez-moi une partie de l’organisation et je
                m’adapterai à
                <span className="texte-color"> votre projet de mariage.</span>
                <br />
                <br />{" "}
              </p>
              <div
                className="btn-more"
                onClick={() =>
                  setDisplayFormule1(displayFormule1 === false ? true : false)
                }
              >
                <p>Réduire</p>
                <span className="icon">
                  <FaCaretUp />
                </span>
              </div>
            </div>
          ) : (
            <div>
              <p>
                Vous vous êtes lancés dans{" "}
                <span className="texte-color">
                  l’organisation de votre mariage
                </span>{" "}
                depuis plusieurs semaines, le temps passe et vous vous rendez
                compte qu’il est <span className="texte-color">difficile</span>{" "}
                de tout combiner : vie privée, vie professionnelle…
              </p>
              <div
                className="btn-more"
                onClick={() =>
                  setDisplayFormule1(displayFormule1 === false ? true : false)
                }
              >
                <p>Voir plus</p>
                <span className="icon">
                  <FaCaretDown />
                </span>
              </div>
            </div>
          )}
        </div>

        <div className="formule2">
          <img
            className="img-formule"
            src="https://i.ibb.co/wKhX6wG/APERITIF-100-12-11zon.jpg"
            alt=""
          />

          <h3>
            Organisation de <br />" A à Z "
          </h3>
          {displayFormule2 === true ? (
            <div>
              <p>
                Il s’agit de la formule la plus{" "}
                <span className="texte-color">complète.</span> <br /> Du jour de
                notre rencontre jusqu’au jour de votre mariage,{" "}
                <span className="texte-color">je serai présente</span> à vos
                côtés pour vous accompagner et vous conseiller à chaque étape :
                <br />
                <br /> <span className="texte-color">Un premier RDV</span> pour
                faire connaissance, cibler vos envies, définir votre budget
                prévisionnel. <br />
                <br /> Visites de lieux, propositions /{" "}
                <span className="texte-color">
                  rencontres des prestataires{" "}
                </span>
                traiteur, photographe etc. <br />
                <br /> Gestion des demandes de devis et des prises de RDV.{" "}
                <br />
                <br />
                Réalisation d’un rétro planning de l’année et du jour J à l’aide
                d’un site internet qui vous sera dédié. <br />
                <br /> <span className="texte-color">RDV réguliers</span> pour
                faire le point ensemble. <br />
                <br /> Ma présence le jour de votre mariage pour{" "}
                <span className="texte-color">coordonner</span> les
                prestataires, régler tous les petits détails et gérer les
                éventuels imprévus… <br />
                <br />
                Avec cette formule, vous pourrez ainsi préparer votre mariage
                sereinement et{" "}
                <span className="texte-color">
                  profiter pleinement le jour J.
                </span>
              </p>
              <div
                className="btn-more"
                onClick={() =>
                  setDisplayFormule2(displayFormule2 === false ? true : false)
                }
              >
                <p>Réduire</p>
                <span className="icon">
                  <FaCaretUp />
                </span>
              </div>
            </div>
          ) : (
            <div>
              <p>
                Il s’agit de la formule la plus{" "}
                <span className="texte-color">complète.</span> <br /> Du jour de
                notre rencontre jusqu’au jour de votre mariage,{" "}
                <span className="texte-color">je serai présente</span> à vos
                côtés pour vous accompagner et vous conseiller à chaque étape :
                Un premier RDV pour faire connaissance...
              </p>
              <div
                className="btn-more"
                onClick={() =>
                  setDisplayFormule2(displayFormule2 === false ? true : false)
                }
              >
                <p>Voir plus</p>
                <span className="icon">
                  <FaCaretDown />
                </span>
              </div>
            </div>
          )}
        </div>
      </div>

      <h3 className="formule-titre">Nouvelles formules ! </h3>
      <div className="formule">
        <div className="formule1">
          <img
            className="img-formule"
            src="https://i.ibb.co/7NB9VyR/Photo-formule-coordination-jour-J.webp"
            alt=""
          />
          <h3>
            Coordination le jour <br /> J
          </h3>
          {displayFormule4 === true ? (
            <div>
              <p>
                Après avoir préparé votre mariage en autonomie, vous
                souhaiteriez
                <span className="texte-color"> déléguer l’organisation</span> du
                jour J pour pouvoir profiter pleinement de votre journée. <br />
                <br />
                Je vous invite alors à me contacter 2 mois avant votre mariage
                afin de définir ensemble le{" "}
                <span className="texte-color">déroulement</span> de la journée.{" "}
                <br />
                <br />
                Cet intervalle de temps me permettra également de contacter tous
                vos prestataires et de régler{" "}
                <span className="texte-color">les derniers détails.</span>
                <br />
                <br />
                Le jour venu, je serai{" "}
                <span className="texte-color">présente à vos côtés</span> tout
                au long de la journée (des préparatifs beauté le matin jusqu’au
                dessert des mariés le soir) pour assurer le bon déroulement de
                votre mariage, coordonner les prestataires, et{" "}
                <span className="texte-color">parer aux imprévus.</span> <br />
                <br />
                Vous n’aurez plus qu’à profiter de votre moitié et de vos
                convives !
              </p>           
              <div
                className="btn-more"
                onClick={() =>
                  setDisplayFormule4(displayFormule4 === false ? true : false)
                }
              >
                <p>Réduire</p>
                <span className="icon">
                  <FaCaretUp />
                </span>
              </div>
            </div>
          ) : (
            <div>
              <p>
              Après avoir préparé votre mariage en autonomie, vous
              souhaiteriez
              <span className="texte-color"> déléguer l’organisation</span> du
              jour J pour pouvoir profiter pleinement de votre journée. <br />
              <br />
              Je vous invite alors à me contacter...
              </p>
              <div
                className="btn-more"
                onClick={() =>
                  setDisplayFormule4(displayFormule4 === false ? true : false)
                }
              >
                <p>Voir plus</p>
                <span className="icon">
                  <FaCaretDown />
                </span>
              </div>
            </div>
          )}
        </div>

        <div className="formule1">
          <img
            className="img-formule-presta"
            src="https://i.ibb.co/jLv8vvV/Lucie.jpg"
            alt=""
          />
          <h3>
            Formule recherche de <br /> prestataires
          </h3>
          {displayFormule5 === true ? (
            <div>
              <p>
                Les préparatifs commencent par{" "}
                <span className="texte-color">
                  la recherche de prestataires{" "}
                </span>
                (lieu de réception, traiteur, photographe, DJ…).
                <br />
                <br /> Je suis là pour{" "}
                <span className="texte-color">
                  vous proposer des professionnels
                </span>{" "}
                qui répondront à vos envies et qui respecteront votre budget.{" "}
                <br />
                <br />
                Pour la réception par exemple, je vous présenterai plusieurs
                lieux que nous pourrons{" "}
                <span className="texte-color">
                  aller visiter ensemble.
                </span>{" "}
                <br />
                <br />
                Je vous{" "}
                <span className="texte-color">
                  accompagnerai jusqu’à la signature
                </span>{" "}
                du contrat avec le lieu de réception que vous aurez choisi.{" "}
                <br />
                <br />
              </p>
              <div
                className="btn-more"
                onClick={() =>
                  setDisplayFormule5(displayFormule5 === false ? true : false)
                }
              >
                <p>Réduire</p>
                <span className="icon">
                  <FaCaretUp />
                </span>
              </div>
            </div>
          ) : (
            <div>
              <p>
              Les préparatifs commencent par{" "}
              <span className="texte-color">
                la recherche de prestataires{" "}
              </span>
              
              <br />
              <br /> Je suis là pour{" "}
              <span className="texte-color">
                vous proposer des professionnels
              </span>{" "}
              qui répondront à vos envies et qui respecteront votre budget.{" "}
              </p>
              <div
                className="btn-more"
                onClick={() =>
                  setDisplayFormule5(displayFormule5 === false ? true : false)
                }
              >
                <p>Voir plus</p>
                <span className="icon">
                  <FaCaretDown />
                </span>
              </div>
            </div>
          )}
        </div>
      </div>

      <NavLink className="fill" to="/contact">
        Me contacter
      </NavLink>

      <div className="weeding-anniv">
        <Fade right>
          <img
            className="weeding"
            src="https://i.ibb.co/H4wKyMf/demandepc-16-11zon.jpg"
            alt=""
          />
        </Fade>
        <p>
          <h3>Anniversaire de mariage</h3>
          Traditionnellement, chaque{" "}
          <span className="texte-color">anniversaire de mariage</span>{" "}
          correspond à un thème ou un matériau de plus en plus solide, pour
          symboliser <span className="texte-color">la force de l’union</span>{" "}
          des époux.
          <br />
          <br /> Qu’il soit de coton, de perle ou d’argent, cet anniversaire est
          l’occasion de se{" "}
          <span className="texte-color">
            remémorer le jour de votre mariage.
          </span>{" "}
          Pour revivre la <span className="texte-color">magie</span> de cette
          journée qui vous a unit quelques années auparavant, vous pouvez faire
          le choix de passer tout simplement un moment à deux ou bien de vous
          entourer de vos proches autour d’une{" "}
          <span className="texte-color">belle fête.</span>
          <br />
          <br /> Ce sera également l’occasion, si vous le souhaitez, de
          <span className="texte-color"> renouveler vos vœux</span> au cours
          d’une cérémonie laïque ou religieuse. Cette offre{" "}
          <span className="texte-color line-anniv">
            « Anniversaire de mariages »
          </span>{" "}
          s’adresse à ceux qui n’ont pas forcément l’énergie, le temps ou
          l’imagination nécessaire pour se lancer dans l’organisation{" "}
          <span className="texte-color">d’un tel évènement.</span>
          <br />
          <br /> Concernant mes prestations, je vous propose{" "}
          <span className="texte-color">les mêmes formules</span>{" "}
          d’accompagnements que pour un mariage : Conseil, organisation
          <span className="texte-color"> partielle</span> ou de{" "}
          <span className="texte-color">A à Z.</span>
        </p>
      </div>
    </div>
  );
};

export default PrestaPc;
