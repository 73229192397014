import React from "react";
import ScrollToTop from "./components/ScrollToTop";
import AnimatedRoute from "./components/AnimatedRoute";
import BtnScrollTop from "./components/BtnScrollTop";

const App = () => {
  return (
    <ScrollToTop>
      <AnimatedRoute />
      <BtnScrollTop />
    </ScrollToTop>
  );
};

export default App;
