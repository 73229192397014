import React from "react";
import Gallery from "react-photo-gallery";
import Measure from "react-measure";
import Lightbox from "react-images";

// Ajouter ici les photos pour le portfolio

const photos = [
  {
      src: "https://i.ibb.co/5j8nNw9/2-bis.webp",
      width: 2,
      height: 3
  },
  {
    src: "https://i.ibb.co/YQwXQgF/1.webp",
    width: 3,
    height: 3
  },
  {
    src: "https://i.ibb.co/4j5JVrd/2.webp",
    width: 2,
    height:3
  },
  {
    src:
      "https://i.ibb.co/2gRmKnL/3.webp",
    width: 2,
    height: 3
  },
  {
    src: "https://i.ibb.co/W0P6Vmq/4-bis.webp",
    width: 3,
    height: 5
  },
  {
    src: "https://i.ibb.co/28YnDn6/4.webp",
    width: 2,
    height: 3
  },
  {
    src:
      "https://i.ibb.co/MstHD1y/7-bis.webp",
    width: 3,
    height:4
  },
  {
    src:
      "https://i.ibb.co/h2mZqDQ/7.webp",
    width: 2,
    height:3
  },
  {
    src:
      "https://i.ibb.co/gFLyT1j/10-bis.webp",
    width: 3,
    height:4
  },
  {
    src:
      "https://i.ibb.co/JsjNNJw/10-resultat.webp",
    width: 3,
    height:3
  },
  {
    src:
      "https://i.ibb.co/RpjzJKK/11-ter-resultat.webp",
    width: 4,
    height:3
  },
  {
    src:
      "https://i.ibb.co/rdbtLLS/11-resultat.webp",
    width: 4,
    height:4
  },
  {
    src:
      "https://i.ibb.co/6NmVn46/12-bis-resultat.webp",
    width: 3,
    height:4
  },
  {
    src:
      "https://i.ibb.co/LkSJC4M/12-resultat.webp",
    width: 3,
    height:4
  },
  {
    src:
      "https://i.ibb.co/q1Cxntv/13-bis-resultat.webp",
    width: 4,
    height:3
  },
  {
    src:
      "https://i.ibb.co/DMgDwCw/13-resultat.webp",
    width: 3,
    height:4
  },
  {
    src:
      "https://i.ibb.co/xgByVVt/14-resultat.webp",
    width: 4,
    height:3
  },
  {
    src:
      "https://i.ibb.co/MRSbwQF/15-resultat.webp",
    width: 3,
    height:3
  },
  {
    src:
      "https://i.ibb.co/qpq6NZZ/17-2-resultat.webp",
    width: 3,
    height:4
  },
  {
    src:
      "https://i.ibb.co/JKkR7Fm/16-2-resultat.webp",
    width: 4,
    height:3
  },
  
  {
    src:
      "https://i.ibb.co/stF0S8c/17-resultat.webp",
    width: 4,
    height:3
  },
  {
    src:
      "https://i.ibb.co/pLBYpqH/18-bis-resultat.webp",
    width: 3,
    height:4
  },
  {
    src:
      "https://i.ibb.co/MBm6P2r/18h00-12.webp",
    width: 4,
    height:3
  },
  {
    src:
      "https://i.ibb.co/47t4yCv/18h00-13-resultat.webp",
    width: 3,
    height:3
  },
  {
    src:
      "https://i.ibb.co/L6BfHL6/18h00-21-resultat.webp",
    width: 3,
    height:4
  },
  {
    src:
      "https://i.ibb.co/rGLQcx3/18h00-20-resultat.webp",
    width: 3,
    height:3
  },
  {
    src:
      "https://i.ibb.co/r6KM858/18h00-90-resultat.webp",
    width: 3,
    height:2
  },
  {
    src:
      "https://i.ibb.co/sCH30Qq/18h00-152-resultat.webp",
    width: 1,
    height:2
  },
  {
    src:
      "https://i.ibb.co/n12RbCn/19-2-resultat.webp",
    width: 4,
    height:4
  },
  {
    src:
      "https://i.ibb.co/w4NbFXn/18h00-210-resultat.webp",
    width: 3,
    height:4
  },
  {
    src:
      "https://i.ibb.co/72mkdHs/19-3-resultat.webp",
    width: 3,
    height:4
  },
  {
    src:
      "https://i.ibb.co/fphygmg/19-4-resultat.webp",
    width: 3,
    height:3
  },
  {
    src:
      "https://i.ibb.co/gjXmDm1/19-6-resultat.webp",
    width: 3,
    height:4
  },
  {
    src:
      "https://i.ibb.co/JKp83H9/19-5-resultat.webp",
    width: 3,
    height:4
  },
  {
    src:
      "https://i.ibb.co/T2bKrF7/20-resultat.webp",
    width: 4,
    height:3
  },
  {
    src:
      "https://i.ibb.co/tXhgC5w/21-resultat.webp",
    width:3,
    height:2
  },
  {
    src:
     "https://i.ibb.co/cwkVFd7/21h00-46-resultat.webp",
    width: 3,
    height:4
  },
  {
    src:
      "https://i.ibb.co/fD1FvH4/21h00-49-resultat.webp",
    width: 3,
    height:4
  },
  {
    src:
      "https://i.ibb.co/SyF47PC/21h00-229-resultat.webp",
    width: 3,
    height:4
  },
  {
    src:
      "https://i.ibb.co/PMqSFsV/21h00-235-resultat.webp",
    width: 4,
    height:4
  },
  {
    src:
      "https://i.ibb.co/KGXKcm3/22-resultat.webp",
    width: 3,
    height:3
  },
  {
    src:
      "https://i.ibb.co/2ggmn1R/24-resultat.webp",
    width: 4,
    height:4
  },
  {
    src:
      "https://i.ibb.co/pfCrtzZ/25-bis-resultat.webp",
    width: 4,
    height:4
  },

];


class ImageMariageLucie extends React.Component {
  constructor() {
    super();
    this.state = { currentImage: 0 };
    this.closeLightbox = this.closeLightbox.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
  }
  openLightbox(event, obj) {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true
    });
  }
  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false
    });
  }
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1
    });
  }
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1
    });
  }
  render() {
    const width = this.state.width;
    return (
      <Measure
        bounds
        onResize={contentRect =>
          this.setState({ width: contentRect.bounds.width })
        }
      >
        {({ measureRef }) => {
          if (width < 1) {
            return <div ref={measureRef} />;
          }
          let columns = 2;
          if (width >= 480) {
            columns = 3;
          }
          if (width >= 1024) {
            columns = 3;
          }
          if (width >= 1824) {
            columns = 4;
          }
          return (
            <div className="gallery" ref={measureRef} style={{ height: "80vh", overflow: "scroll" }}>
              <Gallery
                className="image-gallery"
                photos={photos}
                columns={columns}
                onClick={this.openLightbox}
              />
              <Lightbox
              className="lightbox"
                images={photos}
                onClose={this.closeLightbox}
                onClickPrev={this.gotoPrevious}
                onClickNext={this.gotoNext}
                currentImage={this.state.currentImage}
                isOpen={this.state.lightboxIsOpen}
              />
            </div>
          );
        }}
      </Measure>
    );
  }
}
export default ImageMariageLucie;
