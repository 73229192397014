import React from "react";
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";
import Social from "../../components/Social";
import ImageJuliette from "../../components/images/ImageJuliette";

const PortfolioDemande1 = () => {
  return (
    <div className="portfolio">
      <Navigation />
      <h3>Juliette</h3>
      <h4>Jaune, violet et liberty</h4>
      <p className="text-portf">
        Juliette est née un 30 août comme son <span className='color-texte'>papa et sa grande sœur.<br/><br/></span> Ironie du
        sort, ce chiffre 30 l’a encore suivi le jour de son <span className='color-texte'>baptême</span> qui a eu
        lieu le 30 mai 2019.<br/><br/> Pour cette petite blondinette <span className='color-texte'>pleine de vie,</span> il
        fallait un thème à son image : gaie, frais et gorgé de soleil.
      </p>
      <ImageJuliette />
      <Social />
      <Footer />
    </div>
  );
};

export default PortfolioDemande1;
