import React, { useEffect, useState } from "react";
import EvenementType from "../components/EvenementType";
import PrestaPc from "../components/PrestaPc";
import Footer from "../components/Footer";
import Logo from "../components/Logo";
import Navigation from "../components/Navigation";
import Social from "../components/Social";
import { motion } from 'framer-motion'
import PrestationMariage from "./PrestationMariage";
const Prestations = () => {
  const [matches, setMatches] = useState(window.matchMedia("(min-width: 1025px)").matches)

  useEffect(() => {
    window
    .matchMedia("(min-width: 1025px)")
    .addEventListener('change', e => setMatches( e.matches));
  }, []);
  return (
    <motion.div initial={{opacity:0}}
    animate={{opacity:1}}
     className="prestation"
     exit={{opacity:0,display:"none", transition:{duration: 2}}}>
      <div className="header">
        <div className="header-inside">
          <Navigation />
          <Logo />
          <h2 className="title-prest">Organisation d'évènements</h2>
        </div>
      </div>
      <h2 className="titre-pres">Des Jours Heureux</h2>

      {matches && (<PrestaPc />)}
      {!matches && (<EvenementType />)}
      <Social />
      <Footer />
    </motion.div>
  );
};

export default Prestations;
