import React from "react";
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";

const Mention = () => {
  return (
    <div className="mention">
      <Navigation />
      <div className="text-mention">
      <h4>Edition du site :</h4>
      <p>
        Le site Des Jours Heureux, accessible à l’adresse
        https://www.desjoursheureux.fr est édité par la société Des Jours
        Heureux, dont le siège social est situé 05 rue croix de la reille 34500
        Beziers et est joignable par mail a l’adresse
        luciedesjoursheureux@gmail.com
        <br /> Numéro d’immatriculation 521866368(2022 A 00500)
      </p>
      <h4>Développement :</h4>
      <p>
        Jean-Roch TOMASO <br />
        Email : jeanroch.t@outlook.fr
        <br />
        Téléphone : 07 66 03 29 31
      </p>
      <h4>Hébergement :</h4>
      <p>
        Hébergeur : Hostinger <br />
        Adresse : HOSTINGER INTERNATIONAL LTD, 61 Lordou Vironos Street, 6023
        Larnaca, Chypre
        <br />
        Contact : https://www.hostinger.fr/contact.
      </p>

      <h4>Respect de la propriété intellectuelle</h4>
      <p>
       Toutes les marques, photographies,
      textes, commentaires, illustrations, images animées ou non, séquences
      vidéo, sons, ainsi que toutes les applications informatiques qui
      pourraient être utilisées pour faire fonctionner le Site et plus
      généralement tous les éléments reproduits ou utilisés sur le Site sont
      protégés par les lois en vigueur au titre de la propriété intellectuelle.
      Ils sont la propriété pleine et entière de l’Editeur ou de ses
      partenaires, sauf mentions particulières. Toute reproduction,
      représentation, utilisation ou adaptation, sous quelque forme que ce soit,
      de tout ou partie de ces éléments, y compris les applications
      informatiques, sans l’accord préalable et écrit de l’Editeur, sont
      strictement interdites. Le fait pour l’Editeur de ne pas engager de
      procédure dès la prise de connaissance de ces utilisations non autorisées
      ne vaut pas acceptation desdites utilisations et renonciation aux
      poursuites. Seule l’utilisation pour un usage privé dans un cercle de
      famille est autorisée et toute autre utilisation est constitutive de
      contrefaçon et/ou d’atteinte aux droits voisins, sanctionnées par Code de
      la propriété intellectuelle. La reprise de tout ou partie de ce contenu
      nécessite l’autorisation préalable de l’Editeur ou du titulaire des droits
      sur ce contenu.</p>
      <h4>Lien hypertextes :</h4>
      <p>
       Les sites internet de peuvent offrir
      des liens vers d’autres sites internet ou d’autres ressources disponibles
      sur Internet. Des Jours Heureux ne dispose d’aucun moyen pour contrôler
      les sites en connexion avec ses sites internet. Des Jours Heureux ne
      répond pas de la disponibilité de tels sites et sources externes, ni ne la
      garantit. Elle ne peut être tenue pour responsable de tout dommage, de
      quelque nature que ce soit, résultant du contenu de ces sites ou sources
      externes, et notamment des informations, produits ou services qu’ils
      proposent, ou de tout usage qui peut être fait de ces éléments. Les
      risques liés à cette utilisation incombent pleinement à l’internaute, qui
      doit se conformer à leurs conditions d’utilisation. Les utilisateurs, les
      abonnés et les visiteurs des sites internet  ne peuvent pas mettre en
      place un hyperlien en direction de ce site sans l’autorisation expresse et
      préalable de Des Jours Heureux Dans l’hypothèse où un utilisateur ou
      visiteur souhaiterait mettre en place un hyperlien en direction d’un des
      sites internet de Des Jours Heureux, il lui appartiendra d’adresser un
      email accessible sur le site afin de formuler sa demande de mise en place
      d’un hyperlien. Des Jours Heureux se réserve le droit d’accepter ou de
      refuser un hyperlien sans avoir à en justifier sa décision.
      </p>
      </div>
      <Footer />
    </div>
  );
};

export default Mention;
