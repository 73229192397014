import React from "react";
import Navigation from "../../components/Navigation";
import Footer from "../../components/Footer";
import Social from "../../components/Social";
import ImageMariageVanessa from "../../components/images/ImageMariageVanessa";

const PortfolioMariageVanessa = () => {
  return (
    <div className="portfolio">
      <Navigation />
      <h3>Vanessa & Florian</h3>
      <h4>Un mariage bien mérité</h4>
      <p className="text-portf">
      Après 2 ans et demi d’attente (Covid oblige) et des préparatifs semés d’embûches, ces 2  là ont finis par se dire <span className="color-texte">OUI.</span><br/><br/>
      On dit parfois que ça vaut le coup d’attendre… et bien, <span className="color-texte">ce jour là,</span> cette expression a pris tout son sens. Leur <span className="color-texte">jour J</span> s’est déroulé sans encombre (ou presque !).       
      </p>
      <ImageMariageVanessa />
      <Social />
      <Footer />
    </div>
  );
};

export default PortfolioMariageVanessa;