import React from "react";
import { NavLink } from "react-router-dom";
import Fade from 'react-reveal/Fade';

const PrestationAccueil = () => {
  return (
    <div className="prestation-accueil">
      <div className="prestation-mobile">
        <h2>Prestations</h2>
        <h3>Organisateur de Mariages & D'évènements</h3>
        <p>
          Découvrez tous les évènements pour lesquels je peux vous aider, ainsi
          que les différentes formules d’accompagnement pour les mariages.{" "}
        </p>

        <div className="box">
          <div className="box-type box1">
            <NavLink to="/prestation">Mariages</NavLink>
          </div>
          <div className="box-type box2">
            <NavLink to="/prestation">Baptêmes</NavLink>
          </div>
          <div className="box-type box3">
            <NavLink to="/prestation">Évènements</NavLink>
          </div>
        </div>
        <div className="btn-presta">
          <NavLink className="more-presta" to="/prestation">
            <span className="btn-pc">Découvrez nos prestations en détail</span>
            <span className="btn-mobile">Voir plus</span>
          </NavLink>
        </div>
      </div>

      <div className="prestation-pc">
        <h2>
          Prestations
        </h2>

        <div className="mariage">
          <div className="texte-mariage">
            <p>
              <h3>Mariages</h3>{" "}
              <span className="line-m1">
                Vous avez décidé de vous{" "}
                <span className="color-p">marier</span> et vous devez vous
                lancer dans les préparatifs.
              </span>
              <br /> <br />{" "}
              <span className="line-m2">
                Tout <span className="color-p">un tas de questions</span> se
                pose à vous : 
                <br /> Par où commencer ? Comment gérer le compte à rebours ?
                Quels prestataires choisir ? Ces questions sont tout à fait
                normales et <span className="color-p">je peux vous aider à y répondre.</span> <br />
              </span>
              <br />
              <span className="line-m3">
                {" "}
                Selon <span className="color-p">vos attentes</span> et <span className="color-p">vos envies,</span> je vous propose différentes
                <span className="color-p"> formules d’accompagnement</span> qui vont du simple conseil à
                l’organisation complète de votre mariage.
              </span>
              <NavLink className="fill" to="/prestation">
                Découvrez les formules
              </NavLink>
            </p>
           <Fade left> <img src="https://i.ibb.co/nmMC8j5/mariage2-22-11zon.jpg" className="couple-mariage" alt="" /></Fade>
          </div>
        </div>

        <div className="bapteme">
          <div className="texte-bapteme">
            <Fade right><img src="https://i.ibb.co/cNtw6NJ/bapteme2.webp" className="bapteme-child" alt="" /></Fade>
            <p style={{textAlign:'right'}}>
              <h3>Baptêmes</h3>
              <span className="line-m1">
                Votre enfant est né et vous souhaitez <span className="color-p">célébrer son arrivée </span>par
                un baptême religieux ou civil. Quel que soit votre choix, après
                la cérémonie <span className="color-p">place à la fête !</span>
              </span>
              <br />
              <br />{" "}
              <span className="line-m2">
                Que ce soit à la maison ou dans un lieu de réception, je vous
                aiderai à <span className="color-p">organiser les festivités </span>qui suivront la cérémonie :
                recherche de prestataires, rétro planning, décoration… et ma
                présence <span className="color-p">le jour J</span> si vous le souhaitez. <br />
                <br />
                Je peux également intervenir dans la <span className="color-p">préparation de la cérémonie </span>
                avec la réception et la mise en place des fleurs à l’église par
                exemple. 
              </span>
              <span className="formule-bapteme">*Prestation sur devis selon vos besoins</span>
              <span className="formule-bapteme">**Voir formule mariages</span>
              <NavLink className="fill" to="/contact">
                Contactez moi
              </NavLink>
            </p>
          </div>
        </div>
        <div className="over-event">
          <div className="event-text">
            <p>
              <h3>Autres Evènements</h3>
              <span className="line-m1">
                Pour tout autre <span className="color-p">évènement</span> (anniversaire, PACS, départ à la
                retraite, fête surprise etc.), vous pouvez également avoir
                besoin d’un <span className="color-p">organisateur d’évènements.</span>
              </span>
              <br />
              <br />
              <span className="line-m2">
                {" "}
                Je vous propose une première rencontre pour connaître votre
                projet. Je reviendrai ensuite vers vous avec des <span className="color-p">propositions de
                prestations et</span> un budget prévisionnel.
              </span>
              <span className="formule-bapteme">*Voir formule mariages</span>
              <NavLink className="fill" to="/contact">
                Contactez moi
              </NavLink>
            </p>
           <Fade left> <img src="https://i.ibb.co/Tb8Vmx0/128.webp" className="over-img" alt="" /></Fade>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrestationAccueil;
