import React from "react";
import { NavLink } from "react-router-dom";

const Card = ({ dataEvent }) => {
  return (
    <div className="card-event">
      {dataEvent.map((data) => {
        return (
          // Card Mariage vanessa, 1 = PC, 2 = MOBILE & Tablette

          <div className="card">
            <img
              className="img"
              src={data.mariages[5].image}
              alt="img-mariage"
            />
            <div className="description">
              <h3>
                {data.mariages[5].name}
                <br />
                <span style={{ fontSize: "15px" }}>03 Septembre 2022</span>
              </h3>
              <p>
                Après 2 ans et demi d’attente (Covid oblige) et des préparatifs
                semés d’embûches, ces 2 là ont finis par se dire OUI.
              </p>
              <NavLink to={data.mariages[5].path} className="fill">
                Voir plus
              </NavLink>
            </div>
          </div>
        );
      })}

      {dataEvent.map((data) => {
        return (
          <div className="card-mobile">
            <div className="description">
              <h3>
                {data.mariages[5].name}
                <br />
                <span style={{ fontSize: "15px" }}>03 Septembre 2022</span>
              </h3>
              <p>
                Après 2 ans et demi d’attente (Covid oblige) et des préparatifs
                semés d’embûches, ces 2 là ont finis par se dire{" "}
                <span className="color-texte">OUI.</span>
              </p>
            </div>
            <img
              className="img"
              src={data.mariages[5].image}
              alt="img-bapteme"
            />
            <NavLink to={data.mariages[5].path} className="fill">
              Voir plus
            </NavLink>
          </div>
        );
      })}

      {dataEvent.map((data) => {
        return (
          // Card Anniversaire Monika, 1 = PC, 2 = MOBILE & Tablette

          <div className="card">
            <div className="description">
              <h3>
                {data.mariages[6].name}
                <br />
                <span style={{ fontSize: "15px" }}>23 Juillet 2022</span>
              </h3>
              <p>
                Monika & Emmanuel m’ont contacté une semaine seulement avant
                leur mariage Franco-Polonais… Ils avaient préparé leur mariage à
                distance, aux Pays-Bas, et avaient besoin d’une personne pour
                coordonner les prestataires, gérer le timing de la journée,
                s’occuper d’éventuels imprévus.
              </p>
              <NavLink to={data.mariages[6].path} className="fill">
                Voir plus
              </NavLink>
            </div>
            <img
              className="img"
              src={data.mariages[6].image}
              alt="img-mariage"
            />
          </div>
        );
      })}

      {dataEvent.map((data) => {
        return (
          <div className="card-mobile">
            <div className="description">
              <h3>
                {data.mariages[6].name}
                <br />
                <span style={{ fontSize: "15px" }}>23 Juillet 2022</span>
              </h3>
              <p>
                Monika & Emmanuel m’ont contacté une semaine seulement avant
                leur mariage Franco-Polonais… <br />
                <br />
                Ils avaient préparé leur mariage à distance, aux Pays-Bas, et
                avaient besoin d’une personne pour
                <span className="color-texte">coordonner</span> les
                prestataires, <span className="color-texte">gérer</span> le
                timing de la journée, s’occuper d’éventuels{" "}
                <span className="color-texte">imprévus.</span>
              </p>
            </div>
            <img
              className="img"
              src={data.mariages[6].image}
              alt="img-bapteme"
            />
            <NavLink to={data.mariages[6].path} className="fill">
              Voir plus
            </NavLink>
          </div>
        );
      })}

      {dataEvent.map((data) => {
        return (
          // Card Anniversaire LUCIE, 1 = PC, 2 = MOBILE & Tablette

          <div className="card">
            <img
              className="img"
              src={data.anniversaire[0].image}
              alt="img-mariage"
            />
            <div className="description">
              <h3>
                {data.anniversaire[0].name}
                <br />
                <span style={{ fontSize: "15px" }}>02 Juillet 2022</span>
              </h3>
              <p>
                Un anniversaire qui annonce le début des vacances avec sa déco
                marine. Du bois flotté, quelques filets de pêche, du sable et le
                tour était joué.
              </p>
              <NavLink to={data.anniversaire[0].path} className="fill">
                Voir plus
              </NavLink>
            </div>
          </div>
        );
      })}

      {dataEvent.map((data) => {
        return (
          <div className="card-mobile">
            <div className="description">
              <h3>
                {data.anniversaire[0].name}
                <br />
                <span style={{ fontSize: "15px" }}>02 Juillet 2022</span>
              </h3>
              <p>
                Un anniversaire qui annonce le début des vacances avec sa{" "}
                <span className="color-texte">déco marine. </span>
                Du bois flotté, quelques filets de pêche, du sable et le tour
                était joué.
              </p>
            </div>
            <img
              className="img"
              src={data.anniversaire[0].image}
              alt="img-bapteme"
            />
            <NavLink to={data.anniversaire[0].path} className="fill">
              Voir plus
            </NavLink>
          </div>
        );
      })}

      {dataEvent.map((data) => {
        return (
          // Card Baptême Juliette, 1 = PC, 2 = MOBILE & Tablette

          <div className="card">
            <div className="description">
              <h3>
                {data.mariages[4].name}
                <br />
                <span style={{ fontSize: "15px" }}>18 Juin 2022</span>
              </h3>
              <p>
                Le lieu de réception de ce mariage était hors du commun puisque
                nous étions au cœur d’un haras…magnifique !!{" "}
              </p>
              <NavLink to={data.mariages[4].path} className="fill">
                Voir plus
              </NavLink>
            </div>
            <img
              className="img"
              src={data.mariages[4].image}
              alt="img-mariage"
            />
          </div>
        );
      })}

      {dataEvent.map((data) => {
        return (
          <div className="card-mobile">
            <div className="description">
              <h3>
                {data.mariages[4].name}
                <br />
                <span style={{ fontSize: "15px" }}>18 Juin 2022</span>
              </h3>
              <p>
                Le lieu de réception de ce mariage était hors du commun puisque
                nous étions au cœur d’un{" "}
                <span className="color-texte">haras…magnifique !!</span>
              </p>
            </div>
            <img
              className="img"
              src={data.mariages[4].image}
              alt="img-bapteme"
            />
            <NavLink to={data.mariages[4].path} className="fill">
              Voir plus
            </NavLink>
          </div>
        );
      })}

      {dataEvent.map((data) => {
        return (
          <div className="card">
            <img
              className="img"
              src={data.mariages[3].image}
              alt="img-bapteme"
            />
            <div className="description">
              <h3>
                {data.mariages[3].name}
                <br />
                <span style={{ fontSize: "15px" }}>18 Juin 2022</span>
              </h3>
              <p>
                Un couple que j’ai accompagné avec la formule « Conseil ». Trois
                rendez-vous seulement qui leur ont permis d’y voir plus clair,
                et d’organiser, en toute autonomie, leur mariage en petit
                comité.
              </p>
              <NavLink to={data.mariages[3].path} className="fill">
                Voir plus
              </NavLink>
            </div>
          </div>
        );
      })}

      {dataEvent.map((data) => {
        return (
          // Card Baptême Juliette, 1 = PC, 2 = MOBILE & Tablette

          <div className="card-mobile">
            <div className="description">
              <h3>
                {data.mariages[3].name}
                <br />
                <span style={{ fontSize: "15px" }}>18 Juin 2022</span>
              </h3>
              <p>
                Un couple que j’ai{" "}
                <span className="color-texte">accompagné</span> avec la formule
                « Conseil ».
                <br />
                <br />
                <span className="color-texte">Trois rendez-vous </span>seulement
                qui leur ont permis d’y voir plus clair,
                <br /> <br /> et d’organiser, en toute{" "}
                <span className="color-texte">autonomie</span>, leur mariage en
                petit comité.
              </p>
            </div>
            <img
              className="img"
              src={data.mariages[3].image}
              alt="img-bapteme"
            />
            <NavLink to={data.mariages[3].path} className="fill">
              Voir plus
            </NavLink>
          </div>
        );
      })}

      {dataEvent.map((data) => {
        return (
          // Card Anniversaire sophie, 1 = PC, 2 = MOBILE & Tablette

          <div className="card">
            <div className="description">
              <h3>
                {data.anniversaire[1].name}
                <br />
                <span style={{ fontSize: "15px" }}>10 Juin 2022</span>
              </h3>
              <p>
                Sophie & Julien ont fêté leurs anniversaires dans leur belle
                demeure. Une bâtisse rénovée avec beaucoup de goût et de
                raffinement. Un endroit où je me suis sentie à l’aise pour
                organiser leur soirée.
              </p>
              <NavLink to={data.anniversaire[1].path} className="fill">
                Voir plus
              </NavLink>
            </div>
            <img
              className="img"
              src={data.anniversaire[1].image}
              alt="img-mariage"
            />
          </div>
        );
      })}

      {dataEvent.map((data) => {
        return (
          <div className="card-mobile">
            <div className="description">
              <h3>
                {data.anniversaire[1].name}
                <br />
                <span style={{ fontSize: "15px" }}>10 Juin 2022</span>
              </h3>
              <p>
                Sophie & Julien ont fêté leurs anniversaires dans leur{" "}
                <span className="color-texte">
                  belle demeure.
                  <br />
                </span>{" "}
                Une bâtisse rénovée avec{" "}
                <span className="color-texte">beaucoup de goût</span> et de
                raffinement. Un endroit où je me suis sentie à l’aise pour
                organiser leur soirée.
              </p>
            </div>
            <img
              className="img"
              src={data.anniversaire[1].image}
              alt="img-bapteme"
            />
            <NavLink to={data.anniversaire[1].path} className="fill">
              Voir plus
            </NavLink>
          </div>
        );
      })}

      {dataEvent.map((data) => {
        return (
          // Card Demande en mariage Charlêne, 1 = PC, 2 = MOBILE & Tablette

          <div className="card">
            <img
              className="img"
              src={data.demande[0].image}
              alt="img-bapteme"
            />
            <div className="description">
              <h3>
                {data.demande[0].name}
                <br />
                <span style={{ fontSize: "15px" }}>07 Aout 2021</span>
              </h3>
              <p>
                Après 10 ans d’Amour, une passion commune unissait ces deux
                amoureux.
                <br />
                C’est donc tout naturellement que Jérémy avait choisi de faire
                sa demande sur ce lieu d’escalade : Notre Dame du Cros.
                <br /> Une fois tout en haut, ce lieu leur offrirait un beau
                panorama, symbole de grandeur et d’avenir.
                <br /> Une vue sur une perspective lointaine.
              </p>
              <NavLink to={data.demande[0].path} className="fill">
                Voir plus
              </NavLink>
            </div>
          </div>
        );
      })}
      {dataEvent.map((data) => {
        return (
          <div className="card-mobile">
            <div className="description">
              <h3>
                {data.demande[0].name}
                <br />
                <span style={{ fontSize: "15px" }}>07 Aout 2021</span>
              </h3>
              <p>
                Après <span className="color-texte">10 ans d’Amour,</span> une
                passion commune unissait ces deux amoureux.
                <br />
                <br />
                C’est donc tout naturellement que Jérémy avait choisi de faire
                sa demande sur ce lieu d’escalade :{" "}
                <span className="color-texte">Notre Dame du Cros.</span>
                <br />
                <br /> Une fois tout en haut, ce lieu leur offrirait un beau
                panorama, symbole de{" "}
                <span className="color-texte">grandeur et d’avenir.</span>
                <br />
                <br /> Une vue sur une{" "}
                <span className="color-texte">perspective lointaine.</span>
              </p>
            </div>
            <img
              className="img"
              src={data.demande[0].image}
              alt="img-bapteme"
            />
            <NavLink to={data.demande[0].path} className="fill">
              Voir plus
            </NavLink>
          </div>
        );
      })}

      {dataEvent.map((data) => {
        return (
          // Card mariage Lucie et Julie, 1 = PC, 2 = MOBILE & Tablette

          <div className="card">
            <div className="description">
              <h3>
                {data.mariages[0].name}
                <br />
                <span style={{ fontSize: "15px" }}>26 Juin 2021</span>
              </h3>
              <p>
                Malgré la distance et leurs 3 visites par temps de pluie au
                Château Bas d’Aumelas, Julie & Lucien ont dû se rendre à
                l’évidence, et reconnaître leur coup de cœur pour ce lieu bourré
                de charme.
                <br /> Ils ont eu raison de s’écouter puisque le jour J il
                faisait prés de 39°c… C’est donc sous une chaleur écrasante que
                nos jeunes mariés et leurs convives ont passé cette journée de
                noces.
              </p>
              <NavLink to={data.mariages[0].path} className="fill">
                Voir plus
              </NavLink>
            </div>
            <img
              className="img"
              src={data.mariages[0].image}
              alt="img-mariage"
            />
          </div>
        );
      })}
      {dataEvent.map((data) => {
        return (
          <div className="card-mobile">
            <div className="description">
              <h3>
                {data.mariages[0].name}
                <br />
                <span style={{ fontSize: "15px" }}>26 Juin 2021</span>
              </h3>
              <p>
                Malgré la distance et leurs 3 visites par temps de pluie au
                <span className="color-texte">
                  {" "}
                  Château Bas d’Aumelas,
                </span>{" "}
                Julie & Lucien ont dû se rendre à l’évidence, et reconnaître
                leur coup de cœur pour ce lieu bourré de{" "}
                <span className="color-texte">charme.</span>
                <br />
                <br /> Ils ont eu raison de s’écouter puisque le jour J il
                faisait prés de 39°c… <br />
                <br />
                C’est donc sous une chaleur écrasante que nos jeunes mariés et
                leurs convives ont passé cette{" "}
                <span className="color-texte">journée de noces.</span>
                <br />
                <br /> Durant le{" "}
                <span className="color-texte">vin d’honneur,</span> l’orage
                menaçait et quelques gouttes de pluie sont tombées...
              </p>
            </div>
            <img
              className="img"
              src={data.mariages[0].image}
              alt="img-mariage"
            />
            <NavLink to={data.mariages[0].path} className="fill">
              Voir plus
            </NavLink>
          </div>
        );
      })}

      {dataEvent.map((data) => {
        return (
          // Card bapteme Irêne, 1 = PC, 2 = MOBILE & Tablette

          <div className="card">
            <img
              className="img"
              src={data.baptemes[0].image}
              alt="img-bapteme"
            />
            <div className="description">
              <h3>
                {data.baptemes[0].name}
                <br />
                <span style={{ fontSize: "15px" }}>19 Juin 2021</span>
              </h3>
              <p>
                Recette pour réussir un joli baptême : <br /> A la naissance,
                donnez à votre petite fille un doux prénom empreint de votre
                histoire familiale.
                <br /> Laisser grandir pendant 9 bons mois. <br />
                Le 19 juin 2021, mélangez la famille et les proches dans le
                jardin de la marraine d’Irène...
              </p>
              <NavLink to={data.baptemes[0].path} className="fill">
                Voir plus
              </NavLink>
            </div>
          </div>
        );
      })}
      {dataEvent.map((data) => {
        return (
          <div className="card-mobile">
            <div className="description">
              <h3>
                {data.baptemes[0].name}
                <br />
                <span style={{ fontSize: "15px" }}>19 Juin 2021</span>
              </h3>
              <p>
                Recette pour <span className="color-texte">réussir</span> un
                joli baptême : <br />
                <br />A la naissance, donnez à votre petite fille un{" "}
                <span className="color-texte">doux prénom </span>
                empreint de votre histoire familiale.
                <br />
                <br /> Laisser <span className="color-texte">grandir</span>{" "}
                pendant 9 bons mois.
                <br />
                <br /> Le 19 juin 2021, mélangez la famille et les proches dans
                le jardin de <span className="color-texte">la marraine </span>
                d’Irène...
              </p>
              <img
                className="img"
                src={data.baptemes[0].image}
                alt="img-bapteme"
              />
              <NavLink to={data.baptemes[0].path} className="fill">
                Voir plus
              </NavLink>
            </div>
          </div>
        );
      })}

      {dataEvent.map((data) => {
        return (
          // Card Wedding academy, 1 = PC, 2 = MOBILE & Tablette

          <div className="card">
            <div className="description">
              <h3>
                {data.mariages[2].name}
                <br />
                <span style={{ fontSize: "15px" }}>12 Mars 2021</span>
              </h3>
              <p>
                Dans le cadre de ma formation, la Wedding Academy nous a proposé
                de réaliser un shooting d'inspiration sur le thème des
                chroniques de Bridgerton.
                <br /> Nous avons mis en scène un couple, Marion et Jean-Luc, au
                magnifique Château de la Baronnie, avec la participation
                bénévole de nombreux prestataires...
              </p>
              <NavLink to={data.mariages[2].path} className="fill">
                Voir plus
              </NavLink>
            </div>
            <img
              className="img"
              src={data.mariages[2].image}
              alt="img-bapteme"
            />
          </div>
        );
      })}
      {dataEvent.map((data) => {
        return (
          <div className="card-mobile">
            <div className="description">
              <h3>
                {data.mariages[2].name}
                <br />
                <span style={{ fontSize: "15px" }}>12 Mars 2021</span>
              </h3>
              <p>
                Dans le cadre de ma formation, la{" "}
                <span className="color-texte">Wedding Academy</span> nous a
                proposé de réaliser un shooting d'inspiration sur le thème des
                chroniques de <span className="color-texte">Bridgerton.</span>
                <br />
                <br /> Nous avons mis en scène un couple, Marion et Jean-Luc, au
                magnifique{" "}
                <span className="color-texte">
                  Château de la Baronnie,
                </span>{" "}
                avec la participation bénévole de nombreux prestataires...
              </p>
            </div>
            <img
              className="img"
              src={data.mariages[2].image}
              alt="img-bapteme"
            />
            <NavLink to={data.mariages[2].path} className="fill">
              Voir plus
            </NavLink>
          </div>
        );
      })}

      {dataEvent.map((data) => {
        return (
          // Card mariage Aurelie et Tim, 1 = PC, 2 = MOBILE & Tablette

          <div className="card">
            <img
              className="img"
              src={data.mariages[1].image}
              alt="img-bapteme"
            />
            <div className="description">
              <h3>
                {data.mariages[1].name}
                <br />
                <span style={{ fontSize: "15px" }}>29 Juin 2019</span>
              </h3>
              <p>
                La cérémonie civile d’Aurélie et Tim aura été particulièrement
                éprouvante pour le papa de la mariée puisque c’est lui-même, en
                qualité d'élu, qui les a mariés.
                <br /> Cerise sur le gâteau : c’était une surprise, les mariés
                n’étaient même pas au courant
              </p>
              <NavLink to={data.mariages[1].path} className="fill">
                Voir plus
              </NavLink>
            </div>
          </div>
        );
      })}
      {dataEvent.map((data) => {
        return (
          <div className="card-mobile">
            <div className="description">
              <h3>
                {data.mariages[1].name}
                <br />
                <span style={{ fontSize: "15px" }}>29 Juin 2019</span>
              </h3>
              <p>
                La cérémonie civile{" "}
                <span className="color-texte">d’Aurélie et Tim</span> aura été
                particulièrement éprouvante pour le papa de la mariée puisque
                c’est lui-même, en qualité d’élu, qui les a mariés.
                <br />
                <br />{" "}
                <span className="color-texte">Cerise sur le gâteau :</span>{" "}
                c’était une surprise, les mariés n’étaient même pas au courant
              </p>
            </div>
            <img
              className="img"
              src={data.mariages[1].image}
              alt="img-bapteme"
            />
            <NavLink to={data.mariages[1].path} className="fill">
              Voir plus
            </NavLink>
          </div>
        );
      })}

      {dataEvent.map((data) => {
        return (
          // Card Baptême Juliette, 1 = PC, 2 = MOBILE & Tablette

          <div className="card">
            <div className="description">
              <h3>
                {data.baptemes[1].name}
                <br />
                <span style={{ fontSize: "15px" }}>30 Mai 2019</span>
              </h3>
              <p>
                Juliette est née un 30 août comme son papa et sa grande sœur.
                <br /> Ironie du sort, ce chiffre 30 l’a encore suivi le jour de
                son baptême qui a eu lieu le 30 mai 2019.
                <br />
                Pour cette petite blondinette pleine de vie, il fallait un thème
                à son image : gaie, frais et gorgé de soleil.{" "}
              </p>
              <NavLink to={data.baptemes[1].path} className="fill">
                Voir plus
              </NavLink>
            </div>
            <img
              className="img"
              src={data.baptemes[1].image}
              alt="img-bapteme"
            />
          </div>
        );
      })}
      {dataEvent.map((data) => {
        return (
          <div className="card-mobile">
            <div className="description">
              <h3>
                {data.baptemes[1].name}
                <br />
                <span style={{ fontSize: "15px" }}>30 Mai 2019</span>
              </h3>
              <p>
                Juliette est née un <span className="color-texte">30 août</span>{" "}
                comme son papa et sa grande sœur.
                <br />
                <br /> Ironie du sort, ce chiffre 30 l’a encore suivi le jour de
                son baptême qui a eu lieu le{" "}
                <span className="color-texte">30 mai 2019.</span>
                <br />
                <br />
                Pour cette petite blondinette{" "}
                <span className="color-texte">pleine de vie,</span> il fallait
                un thème à son image : gaie, frais et gorgé de{" "}
                <span className="color-texte">soleil.</span>
              </p>
            </div>
            <img
              className="img"
              src={data.baptemes[1].image}
              alt="img-bapteme"
            />
            <NavLink to={data.baptemes[1].path} className="fill">
              Voir plus
            </NavLink>
          </div>
        );
      })}
    </div>
  );
};

export default Card;
