import React, {useState} from 'react';

import { IoMdArrowDropup } from "@react-icons/all-files/io/IoMdArrowDropup";

const BtnScrollTop = () =>{
  
    const [btnVisible, setBtnVisible] = useState(false)
  
    const visible = () => {
      const topScrolled = document.documentElement.scrollTop;

      // VISIBLE a partir de 300px

      if (topScrolled > 300){
        setBtnVisible(true)
      } 
      else if (topScrolled <= 300){
        setBtnVisible(false)
      }
    };
    
    const scrollTop = () =>{
      window.scroll({
        top: 0, 
        behavior: 'smooth'
      });
    };
  
    
    window.addEventListener('scroll', visible);
  
  return (

    // Bouton de scroll TOP version MOBILE

     <div  
     className="container" onClick={scrollTop} 
     style={{display: btnVisible ? 'inline' : 'none'}}>
            <IoMdArrowDropup className='top'/>
        </div>
  );
}
  
export default BtnScrollTop;