import React from "react";
import Gallery from "react-photo-gallery";
import Measure from "react-measure";
import Lightbox from "react-images";

// Ajouter ici les photos pour le portfolio

const photos = [
  {
      src: "https://i.ibb.co/PCCPZyv/1.webp",
      width: 2,
      height: 3
  },
  {
    src: "https://i.ibb.co/R61VdsW/2.webp",
    width: 3,
    height: 2
  },
  {
    src: "https://i.ibb.co/6DSQtgX/3.webp",
    width: 3,
    height:2
  },
  {
    src:
      "https://i.ibb.co/0Xg2H3H/4.webp",
    width: 2,
    height: 3
  },
  {
    src: "https://i.ibb.co/dDBrBVV/5.webp",
    width: 3,
    height: 3
  },
  {
    src: "https://i.ibb.co/G5N2qFh/6.webp",
    width: 2,
    height: 3
  },
  {
    src:
      "https://i.ibb.co/Kj5Lxn0/7.webp",
    width: 3,
    height:4
  },
  {
    src:
      "https://i.ibb.co/4N9ZJZ5/8.webp",
    width: 3,
    height:2
  },
  {
    src:
      "https://i.ibb.co/6vYwqVK/9.webp",
    width: 3,
    height:2
  },
  {
    src:
      "https://i.ibb.co/4ZLj1PF/10.webp",
    width: 2,
    height:3
  },
  {
    src:
      "https://i.ibb.co/h10WtR6/11.webp",
    width: 3,
    height:4
  },
  {
    src:
      "https://i.ibb.co/BG9tR6Z/12.webp",
    width: 3,
    height:2
  },
  {
    src:
      "https://i.ibb.co/XtKMMWj/13.webp",
    width: 2,
    height:1
  },
  {
    src:
      "https://i.ibb.co/pKQJnLY/14.webp",
    width: 3,
    height:4
  },
  {
    src:
      "https://i.ibb.co/x2kT277/15.webp",
    width: 2,
    height:3
  },
  {
    src:
      "https://i.ibb.co/SdPqWq2/16.webp",
    width: 3,
    height:4
  },
  {
    src:
      "https://i.ibb.co/RQyzJGc/17.webp",
    width: 4,
    height:3
  },
  {
    src:
      "https://i.ibb.co/wyTn8m2/18.webp",
    width: 2,
    height:3
  },
  {
    src:
      "https://i.ibb.co/rFBV5FF/20.webp",
    width: 3,
    height:4
  },
  {
    src:
      "https://i.ibb.co/Pmg88j5/19.webp",
    width: 2,
    height:3
  },
  
  {
    src:
      "https://i.ibb.co/6X04V6d/22.webp",
    width: 4,
    height:3
  },
  {
    src:
      "https://i.ibb.co/9nd2jgy/21.webp",
    width: 3,
    height:4
  },
  {
    src:
      "https://i.ibb.co/yqKjd4K/23.webp",
    width: 2,
    height:3
  }
];


class ImageWa extends React.Component {
  constructor() {
    super();
    this.state = { currentImage: 0 };
    this.closeLightbox = this.closeLightbox.bind(this);
    this.openLightbox = this.openLightbox.bind(this);
    this.gotoNext = this.gotoNext.bind(this);
    this.gotoPrevious = this.gotoPrevious.bind(this);
  }
  openLightbox(event, obj) {
    this.setState({
      currentImage: obj.index,
      lightboxIsOpen: true
    });
  }
  closeLightbox() {
    this.setState({
      currentImage: 0,
      lightboxIsOpen: false
    });
  }
  gotoPrevious() {
    this.setState({
      currentImage: this.state.currentImage - 1
    });
  }
  gotoNext() {
    this.setState({
      currentImage: this.state.currentImage + 1
    });
  }
  render() {
    const width = this.state.width;
    return (
      <Measure
        bounds
        onResize={contentRect =>
          this.setState({ width: contentRect.bounds.width })
        }
      >
        {({ measureRef }) => {
          if (width < 1) {
            return <div ref={measureRef} />;
          }
          let columns = 2;
          if (width >= 480) {
            columns = 3;
          }
          if (width >= 1024) {
            columns = 3;
          }
          if (width >= 1824) {
            columns = 4;
          }
          return (
            <div className="gallery" ref={measureRef} style={{ height: "80vh", overflow: "scroll" }}>
              <Gallery
                className="image-gallery"
                photos={photos}
                columns={columns}
                onClick={this.openLightbox}
              />
              <Lightbox
              className="lightbox"
                images={photos}
                onClose={this.closeLightbox}
                onClickPrev={this.gotoPrevious}
                onClickNext={this.gotoNext}
                currentImage={this.state.currentImage}
                isOpen={this.state.lightboxIsOpen}
              />
            </div>
          );
        }}
      </Measure>
    );
  }
}
export default ImageWa;
