import React from 'react';

const NotFound = () => {
    return (
        <div>
            ERREUR 404 PAGE NON DISPONIBLE
        </div>
    );
};

export default NotFound;