import React from 'react';
import { NavLink } from 'react-router-dom'
import LogoScrool from './LogoScrool';
import { FaFacebookF } from "@react-icons/all-files/fa/FaFacebookF";
import { FaInstagram } from "@react-icons/all-files/fa/FaInstagram";
import { useState } from "react"
import Logo from '../assets/_LOGO_SVG/logoweb.webp'
const Navigation = () => {
  
        const [navBar, setNavBar] = useState(false)
      
        const toggleVisible = () => {
          const scrolled = document.documentElement.scrollTop;
          if (scrolled > 300){
            setNavBar(true)
          } 
          else if (scrolled <= 300){
            setNavBar(false)
          }
        };
        
        window.addEventListener('scroll', toggleVisible);
    return (
        <div className="navigation">
        <input id="toggle" type="checkbox"></input>

<label for="toggle" className="hamburger">
  <div className="top-bun"></div>
  <div className="meat"></div>
  <div className="bottom-bun"></div>
</label>
        <div className="nav">
        <div className='nav-wrapper'>
        <nav>
            <NavLink to="/" className={(navData) => (navData.isActive ? "active-nav" : "")}>
                Home            
            </NavLink>
            <NavLink to="/prestation" className={(navData) => (navData.isActive ? "active-nav" : "")}>
                Formules            
            </NavLink>
            <NavLink to="/portfolio" className={(navData) => (navData.isActive ? "active-nav" : "")}>
                Portfolio            
            </NavLink>
            <NavLink to="/contact" className={(navData) => (navData.isActive ? "active-nav" : "")}>
                Contact            
            </NavLink>
            </nav>
        </div>
        </div>
        <div  className={navBar ? "navigation-pc nav-scroll" : "navigation-pc"}>
        <LogoScrool />

        
        <div className='nav-pc'>
        <img src={Logo} className='logo-nav' alt=""/>
        <NavLink to="/" className={(navData) => (navData.isActive ? "active-nav" : "")}>
        Home            
        </NavLink>
        <NavLink to="/prestation" className={(navData) => (navData.isActive ? "active-nav" : "")}>
        Formules            
        </NavLink>
        <NavLink to="/portfolio" className={(navData) => (navData.isActive ? "active-nav" : "")}>
        Portfolio            
        </NavLink>
        <NavLink to="/contact" className={(navData) => (navData.isActive ? "active-nav" : "")}>
        Contact            
        </NavLink>
        <div className="social-nav">
        <a className="" target="_blank" rel="noreferrer noopener" href="https://www.facebook.com/weddingplannerbeziers">
          <FaFacebookF />
        </a>
        <a className="" target="_blank" rel="noreferrer noopener" href="https://instagram.com/wedding_planner_beziers?igshid=YTM0ZjI4ZDI=">
          <FaInstagram />
        </a>
        </div>
        </div>
        </div>
        </div>
    );
};



export default Navigation;