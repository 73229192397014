import React from 'react';
import logo from '../assets/_LOGO_SVG/logoweb.webp'

const Logo = () => {
    return (
        <div className="logo">
            <img className="img-logo" src={logo} alt=""/>
        </div>
    );
};

export default Logo;